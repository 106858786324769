function ClaimSeatForm() {
    return (
        <div>
            <div className='w-full mx-auto py-6'>
                <div className='relative flex flex-col justify-center items-center w-[100%]'>

                    <h1 className='font-[GothamBold] text-[25px] sm:text-[38px] md:text-[45px] lg:text-[50px] tracking-wider text-center mb-4'>
                        Talk With Us
                    </h1>
                    <p className='font-[GothamBook] text-[17px] sm:text-[19] md:text-[22px] lg:text-[25px] text-center tracking-normal sm:tracking-normal md:tracking-wide lg:tracking-wide w-2/4 mb-7'>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam, earum officia! Quidem earum
                        autem cumque in sit nemo voluptates quae, itaque molestias reprehenderit.
                    </p>
                </div>
                <div className='flex w-8/12 mx-auto shadow-lg'>
                    <div className='w-1/2 py-16 px-12 bg-[#2575BA]'>
                        <h1 className='text-white text-3xl mb-4 w-full'>Claim your Seat</h1>
                        <form action="#" className='mt-16' >
                            <div className='grid grid-cols-1 gap-5'>
                                <input type="text" placeholder='Full Name' className='border border-white py-1 px-2 bg-[#2575BA] placeholder-white' />
                                <input type="text" placeholder='Email' className='border border-white py-1 px-2 bg-[#2575BA] placeholder-white' />
                                <input type="text" placeholder='Phone Number' className='border border-white py-1 px-2 bg-[#2575BA] placeholder-white' />
                            </div>
                            <div className='mt-5'>
                                <button className='w-full bg-white py-3 text-center'>CLAIM</button>
                            </div>
                        </form>
                    </div>
                    <div className='w-1/2'>
                        <img src="/images/claim-seat.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClaimSeatForm;