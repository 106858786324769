import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Agenda from './Bootstrap Components/Agenda/Agenda';
import Media from './Bootstrap Components/Media/Media';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import MainHeader from './Components/MainHeader/MainHeader';
import Nav from './Components/Nav/Nav';
import Sponsors from './Components/Sponsors/Sponsors';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import ClaimSeat from './pages/ClaimSeat/ClaimSeat';
import Program from './pages/Program/Program';
import SponsorsPage from './pages/SponsorsPage/SponsorsPage';
import Hotel from './pages/Lodging/Hotel';
import Exposant from './Bootstrap Components/Participer/exposant/Exposant';
import Login from './Bootstrap Components/Login/login';
import Participer from './pages/Participer/participer';
import Participant from './Bootstrap Components/Participer/Participant/Participant';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import BookForm from './Components/BookHotel/BookForm';
import Intervenant from './Bootstrap Components/Participer/intervenant/Intervenant';
import MediaPage from './pages/MediaPage/MediaPage';
import Press from './pages/Press/Press';
import SideEvents from './pages/SideEvents/SideEvents';
import Uni from './pages/SideEvents/Uni';
import Socco from './pages/SideEvents/Socco';
import PositiveAgendaAdvisory from './pages/SideEvents/PositiveAgendaAdvisory';
import GIZ from './pages/SideEvents/GIZ';
import ThinkTank from './pages/SideEvents/ThinkTank';
import ParticipationOfCivilSociety from './pages/SideEvents/ParticipationOfCivilSociety';
import AIEM from './pages/SideEvents/AIEM';
import ConnectingInternational from './pages/SideEvents/ConnectingInternational';
import Event4CMAROC from './pages/SideEvents/Event4CMAROC';
import GapFundTraining from './pages/SideEvents/GapFundTraining';

function App() {
  return (
    <div id='app-container'>
      <BrowserRouter>
      <ScrollToTop></ScrollToTop>
        <Nav></Nav>
        <div className='mb-[97px] lg:mb-[99px] xl:mb-[123px]'></div>
        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='/about' element={<About></About>}></Route>
          <Route path='/press' element={<Press></Press>}></Route>
          <Route path='/contact' element={<Contact></Contact>}></Route>
          <Route path='/claim' element={<ClaimSeat></ClaimSeat>}></Route>
          <Route path='/program' element={<Program></Program>}></Route>
          <Route path='/media' element={<MediaPage />}></Route>
          <Route path='/sponsors' element={<SponsorsPage></SponsorsPage>}></Route>
          <Route path='/hotels' element={<Hotel></Hotel>}></Route>
          <Route path='/press' element={<Press></Press>}></Route>
          <Route path='/login' element={<Login></Login>}></Route>
          <Route path='/participer' element={<Participer></Participer>}></Route>
          <Route path='/participer/exposant' element={<Exposant></Exposant>}></Route>
          <Route path='/participer/participant' element={<Participant></Participant>}></Route>
          <Route path='/participer/intervenant' element={<Intervenant></Intervenant>}></Route>
          <Route path='/book-hotel/:name' element={<BookForm/>}></Route>
          <Route path='/side-events' element={<SideEvents/>}></Route>
          <Route path='/side-events/uni' element={<Uni/>}></Route>
          <Route path='/side-events/socco' element={<Socco/>}></Route>
          <Route path='/side-events/Positive Agenda Advisory' element={<PositiveAgendaAdvisory/>}></Route>
          <Route path='/side-events/GIZ' element={<GIZ/>}></Route>
          <Route path='/side-events/Think Tank' element={<ThinkTank/>}></Route>
          <Route path='/side-events/AIEM' element={<AIEM/>}></Route>
          <Route path='/side-events/Participation of civil society and startups' element={<ParticipationOfCivilSociety/>}></Route>
          <Route path='/side-events/ConnectingInternational' element={<ConnectingInternational/>}></Route>
          <Route path='/side-events/4C Maroc' element={<Event4CMAROC/>}></Route>
          <Route path='/side-events/Gap Fund Training' element={<GapFundTraining/>}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div >
  );
}

export default App;
