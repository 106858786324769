import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import { useState } from 'react';
import Modal from '../../Components/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faLink } from '@fortawesome/free-solid-svg-icons';

function PressArticles() {

  const { t, i18n, ready } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [more, setMore] = useState(false);
  const [moreText, setMoreText] = useState("");
  const communication = t("press.communication", { returnObjects: true });
  const communication1 = t("press.communication1", { returnObjects: true });
  const communication0 = t("press.communication0", { returnObjects: true });


  return (
    <>
    <div className={'Hotel_4 flex flex-wrap items-center justify-center mt-16 mb-10 '
          + "  "}>
          <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={communication0.image} alt="" />
          <div className={'aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                {communication0.title}
              </h1>
            </div>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <p className={'flex text-[18px] text-justify ' + (isRtl ? " items-end " : " font-[GothamBook] ")}>
                {communication0.description}
              </p>
            </div>
            <div className='flex gap-5'>
              {/* view more button */}
              <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                href={"/pdf/" + communication0.pdf} target='_blank'
              >
                {communication0.more}
              </a>
            </div>
          </div>
        </div >
      <div className='flex flex-col space-y-10 px-[5%] sm:px-[10%] pt-20'>

        {t("press.videos", { returnObjects: true }, { interpolation: { escapeValue: false } }).map((item, index) => {
          return (
            <div className={' flex flex-wrap items-center justify-center ' + (item.description == "" ? " pb-10 " : "")}>
              <iframe className='hotelIMG w-[100%] lg:w-[50%] xl:w-[40%] h-[350px] object-cover object-center ' src={"https://www.youtube.com/embed/" + item.videoID} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <div className={'aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
                <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
                  <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                    {item.title}
                  </h1>
                </div>
                <div className={isRtl ? 'rtl-container ' : ''}>
                  <p dangerouslySetInnerHTML={{ __html: item.description }} className={'text-[18px] text-justify'}>

                  </p>
                </div>
                <div className='flex gap-5'>
                  {/* view more button */}
                  <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                    href={"https://www.youtube.com/watch?v=" + item.videoID} target='_blank'
                  >
                    {t("press.videoMore", { returnObjects: true })}
                  </a>
                </div>
              </div>
            </div >
          );
        })}



        <div className={'Hotel_4 flex flex-wrap items-center justify-center mt-16 mb-10 '
          + "  "}>
          <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={communication1.image} alt="" />
          <div className={'aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                {communication1.title}
              </h1>
            </div>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <p className={'flex text-[18px] text-justify ' + (isRtl ? " items-end " : " font-[GothamBook] ")}>
                {communication1.description}
              </p>
            </div>
            <div className='flex gap-5'>
              {/* view more button */}
              <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                href={"/pdf/" + communication1.pdf} target='_blank'
              >
                {communication1.more}
              </a>
            </div>
          </div>
        </div >
        <div className={'Hotel_4 flex flex-wrap items-center justify-center mt-16 mb-10 '
          + "  "}>
          <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={communication.image} alt="" />
          <div className={'aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                {communication.title}
              </h1>
            </div>
            <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
              <p className={'flex text-[18px] text-justify ' + (isRtl ? " items-end " : "")}>
                {communication.description}
              </p>
            </div>
            <div className='flex gap-5'>
              {/* view more button */}
              <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                href={"/pdf/" + communication.pdf} target='_blank'
              >
                {communication.more}
              </a>
            </div>
          </div>
        </div >

        {t("press.articles", { returnObjects: true }).map((item, index) => {
          return (
            <div className={'Hotel_4 flex flex-wrap items-center justify-center '
              + (index % 2 == 1 ? " flex-row-reverse " : "")}>
              <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={item.image} alt="" />
              <div className={'aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
                <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
                  <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                    {item.title}
                  </h1>
                </div>
                <div className={isRtl ? 'rtl-container rtl-text' : ''}>
                  <p className={'text-[18px] text-justify'}>
                    {item.shortDescription}
                  </p>
                </div>
                <div className='flex gap-5'>
                  {/* view more button */}
                  <button className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4' onClick={() => {
                    setMore(true);
                    setMoreText(() => {
                      return (
                        <div className='flex flex-col justify-center px-5 '>
                          <h1 className={' text-[33px] text-[#3CAA76]'}>
                            {item.title}
                          </h1>
                          <p className={' mt-4 text-justify'}>
                            {item.description}
                          </p>
                        </div>
                      )
                    })
                  }}>{t("home.articles", { returnObjects: true })[0].more}</button>
                </div>
              </div>
            </div >
          );
        })}


        {/* News */}
        {t("press.news", { returnObjects: true }).map((item, index) => {
          return (
            <div className={'Hotel_4 flex flex-wrap items-center justify-center '}>
              <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={item.image} alt="" />
              <div className={'aboutHotel flex flex-col gap-4 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10' + (isRtl ? " items-end " : "")}>
                <div className={isRtl ? 'rtl-container  rtl-text' : ''}>
                  <h1 className={'text-[30px] text-justify text-[#1b5b92]'}  >
                    {item.title}
                  </h1>
                </div>
                <a target='_blank' href={item.url} className={'text-[18px] text-[#47479f] '}>
                  {item.url.split("/")[0]+"//"+item.url.split("/")[2]}
                </a>
                <div className={isRtl ? 'rtl-container rtl-text' : ''}>

                  <p className={'text-[18px] text-justify'}>
                    {item.description}
                  </p>
                </div>
                <div className='flex gap-5'>
                  {/* view more button */}
                  <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                    href={item.url}
                    target='_blank'
                  >
                    {t("home.articles", { returnObjects: true })[0].more}
                    <FontAwesomeIcon className='ml-2' icon={faExternalLink} />
                  </a>
                </div>
              </div>
            </div >
          );
        })}
      </div>
      <Modal autoWidth={true} visible={more} setVisible={setMore}>{moreText}</Modal>
    </>
  )
}

export default PressArticles;
