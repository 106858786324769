import { useEffect } from 'react';
import Header from '../../Components/Header/Header';
import Title from '../../Components/Title/Title';
import sponsorsList from './sponsorsList.json'
import AOS from "aos";
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

function SponsorsPage() {

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        async function loadAOS() {
            await delay(100);
            AOS.init();
            AOS.refresh();
        }
        loadAOS();
    }, []);

    return (
        <>
            <Header title={t("sponsors.headerTitle", { returnObjects: true })} bgImg={"/images/about-us/cap-spartel.jpg"}></Header>


            <div className='flex justify-center mt-16 mb-6'
                data-aos="fade-up"
            >
                <Title name={t("sponsors.coOrganizer", { returnObjects: true })}></Title>
            </div>
            <div className='flex flex-wrap justify-between px-4 md:px-12 lg:px-20 mb-10 '>
                {/* <div className='flex flex-wrap justify-center gap-4 md:gap-10 px-4 md:px-12 lg:px-20 mb-10 '> */}


                <div data-aos="fade-up"
                    className={"relative flex flex-col justify-center items-center md:mt-10 py-3  "
                        + (false ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] mt-4 md:mt-0 ")}>
                    <img className='relative z-10 w-3/4' src={"/images/sponsors/dar-lmonakh.png"} alt="" />
                    <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                        className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                </div>
                <div data-aos="fade-up"
                    className={"relative flex flex-col justify-center items-center md:mt-10 py-3  "
                        + (false ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] mt-4 md:mt-0 ")}>
                    <img className='relative z-10 w-3/4' src={"/images/sponsors/tanger-tetouan.png"} alt="" />
                    <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                        className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                </div>
            </div>

            <div className='flex justify-center mt-16 mb-6'
                data-aos="fade-up"
            >
                <Title name={t("sponsors.institutionalPartners", { returnObjects: true })}></Title>
            </div>
            <div className='flex flex-wrap justify-around md:gap-10 px-4 md:px-12 lg:px-20 mb-10 '>
                {sponsorsList.institutional.map(item => {
                    return <div data-aos="fade-up"
                        className={"relative flex flex-col justify-center items-center mt-2 md:mt-10 py-3  "
                            + (item.aspect === "1" ? " w-[calc(50%-8px)] md:w-[calc(30%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] ")}>
                        <img className='relative z-10 w-3/4' src={"/images/sponsors/" + item.name} alt="" />
                        <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                            className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                        {/* <button className="bg-[#EDF8FE] px-2 py-1">View Details {item['aspect-ratio']}</button> */}
                    </div>
                })}
            </div>


            <div className='flex justify-center mt-16 mb-6'
                data-aos="fade-up"
            >
                <Title name={t("sponsors.partners", { returnObjects: true })}></Title>
            </div>
            <div className='flex flex-wrap justify-around px-4 md:px-12 lg:px-20 mb-10 '>
                {sponsorsList.normal.map(item => {
                    return <div data-aos="fade-up"
                        className={"relative flex flex-col justify-center items-center md:mt-10 py-3 mt-4 aspect-square "
                            // + (item.aspect === "1" ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] ")}>
                            + (false ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(30%-29px)] ")}>
                        <img className='relative z-10 w-3/4' src={"/images/sponsors/" + item.name} alt="" />
                        <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                            className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                        {/* <button className="bg-[#EDF8FE] px-2 py-1">View Details {item['aspect-ratio']}</button> */}
                    </div>
                })}
            </div>

            <div className='flex justify-center mt-16 mb-6'
                data-aos="fade-up"
            >
                <Title name={t("home.sponsors.sponsors", { returnObjects: true })}></Title>
            </div>
            <div className='flex flex-wrap justify-around px-4 md:px-12 lg:px-20 mb-10 '>
                <div data-aos="fade-up"
                    className={"relative flex flex-col justify-center items-center md:mt-10 py-3 mt-4 aspect-square "
                        // + (item.aspect === "1" ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] ")}>
                        + (false ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(30%-29px)] ")}>
                    <img className='relative z-10 w-3/4' src={"/images/sponsors/" + sponsorsList.OCP.name} alt="" />
                    <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                        className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                    {/* <button className="bg-[#EDF8FE] px-2 py-1">View Details {item['aspect-ratio']}</button> */}
                </div>
            </div>

            <div className='flex flex-wrap justify-around px-4 md:px-12 lg:px-20 mb-10 '>
                {sponsorsList.sponsors.map(item => {
                    return <div data-aos="fade-up"
                        className={"relative flex flex-col justify-center items-center md:mt-10 py-3 mt-4 aspect-square "
                            // + (item.aspect === "1" ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(50%-29px)] ")}>
                            + (false ? " w-[calc(50%-8px)] md:w-[calc(25%-34px)] " : " w-[100%] md:w-[calc(30%-29px)] ")}>
                        <img className='relative z-10 w-3/4' src={"/images/sponsors/" + item.name} alt="" />
                        <div style={{ "filter": "drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15))" }}
                            className='absolute top-0 left-0 z-0 w-full h-full bg-[#EEF9FD] '></div>
                        {/* <button className="bg-[#EDF8FE] px-2 py-1">View Details {item['aspect-ratio']}</button> */}
                    </div>
                })}
            </div>
        </>
    );
}

export default SponsorsPage;