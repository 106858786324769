import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
  };

  useEffect(() => {
    console.log(i18n);
  })

  return (
    <div className="flex items-center justify-center text-[18px] space-x-2 ml-4">
      {/* <select className="form-select" onChange={changeLanguage} defaultValue={i18n.language}>
        <option value="en">English</option>
        <option value="fr">Français</option>
        <option value="ar" className='rtl' >العربية</option>
      </select> */}
      {i18n.language !== "ar" ? <button className='flex justify-center items-center' onClick={() => changeLanguage("ar")}>
        <span>ar</span>
        <img className='h-5 ml-1' src="/images/ar.png" alt="" />
      </button> : ""}

      {i18n.language !== "en" ? <button className='flex justify-center items-center' onClick={() => changeLanguage("en")}>
        <span>en</span>
        <img className='h-5 ml-1' src="/images/en.png" alt="" />
      </button> : ""}
      {i18n.language !== "fr" ? <button className='flex justify-center items-center' onClick={() => changeLanguage("fr")}>
        <span>fr</span>
        <img className='h-5 ml-1' src="/images/fr.png" alt="" />
      </button> : ""}

    </div>
  );
};

export default LanguageSwitcher;
