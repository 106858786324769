import { useEffect, useState } from 'react';
import './Nav.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import AOS from "aos";
import 'aos/dist/aos.css';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowDownShortWide, faCaretDown, faCaretUp, faTurnDown } from '@fortawesome/free-solid-svg-icons';
import ParticipateButton from '../ParticipateButton/ParticipateButton';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

function Nav() {

    const [menuOpen, setMenuOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const [dropDown, setDropDown] = useState(false);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <nav className="fixed top-0 left-0 flex flex-col w-full z-[999]"
            data-aos="fade-down"
        >

            {/******** before Nav ********/}
            <div className="flex w-full bg-[#E1F4FC] py-1 pl-6 pr-10 ">
                <div className="w-0 lg:w-44 xl:w-60" alt="" />
                <ul className='flex space-x-4 text-[#808080] text-sm lg:ml-16 xl:ml-20'>
                    {t('pre-nav', { returnObjects: true }).map((item, index) => {
                        return <li>
                            <Link to={item.url} >
                                <span>{item.text}</span>
                            </Link>
                        </li>
                    })}
                </ul>

                <ul className='hidden md:flex space-x-2 ml-auto '>
                    <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100092592355874&sk=about_details"><img className='w-6' src="/images/facebook-icon.png" alt="" /></a></li>
                    <li><a target='_blank' href="https://twitter.com/medcop23"><img className='w-6' src="/images/twitter-icon.png" alt="" /></a></li>
                    <li><a target='_blank' href="https://www.linkedin.com/company/medcop-climate/?viewAsMember=true"><img className='w-6' src="/images/linkedin-icon.png" alt="" /></a></li>
                    <li><a target='_blank' href="https://www.youtube.com/@MedcopClimate/about"><img className='w-6' src="/images/youtube-icon.png" alt="" /></a></li>
                    <li><a target='_blank' href="https://www.instagram.com/medcopclimate/"><img className='w-6' src="/images/instagram-icon.png" alt="" /></a></li>
                    {/* <li className='flex items-center gap-1 ml-2'>EN<img className='h-5' src="/images/en.png" alt="" /></li> */}
                </ul>
                <div className='ml-auto md:ml-0'>
                    <LanguageSwitcher></LanguageSwitcher>
                </div>
            </div>

            {/******* Main Nav *******/}
            <div className="relative flex justify-between items-center bg-[#EEF7F3] pl-6 pr-10 py-1 lg:text-[13px] xl:text-[16px] font-[GothamBook]">

                {/******* Logo ******/}
                <div>
                    <a href="/"><img className="hidden lg:inline-block lg:w-[250px] xl:w-[300px]" src="/images/logo_wide.png" alt="" /></a>
                    <a href="/"><img className="w-[200px] lg:w-0 lg:hidden" src="/images/logo_medcop.png" alt="" /></a>
                </div>

                {/******** Desktop Menu ********/}
                <ul className={"desktop-menu hidden lg:flex lg:items-center lg:justify-center lg:-ml-6 xl:-ml-10 list-none text-center text-black "
                    + (i18n.language === "ar" ? " flex-row-reverse " : "")}>
                    {t("nav.links", { returnObjects: true }).map((item) => {
                        if (item.subMenu) {
                            return <li className='submenu relative lg:ml-6 xl:ml-10'>
                                <a className={"cursor-pointer " + (pathname.includes(item.url) ? ' text-[#F4AE3F] font-semibold active-link' : '')}
                                >
                                    <span>{item.text}</span>

                                </a>
                                <div className={'hidden absolute bottom-0 left-0 translate-y-[100%] w-max max-w-[300px] bg-[#E1F4FC] px-5 py-3 '
                                    + ' flex flex-col '}>
                                    {item.subMenu.map((link, i) => {
                                        return <a className={'text-[#000] hover:text-[#F4AE3F] text-center py-2 '
                                            + (i !== 0 ? " border-t-2 border-[#F4AE3F] " : "")} href={link.url}
                                            target={link.newTab === "true" ? "_blank" : "_self"}>
                                            {link.text}
                                        </a>
                                    })}
                                </div>
                            </li>
                        }
                        else {
                            return <li className='lg:ml-6 xl:ml-10' >
                                <NavLink className={({ isActive }) => {
                                    return isActive ? ' text-[#F4AE3F] font-semibold active-link' : ''
                                }} to={item.url}>
                                    <span>{item.text}</span>
                                </NavLink>
                            </li>
                        }
                    })}
                </ul>

                {/****** "Participer" button ******/}
                <div className="hidden lg:flex items-center space-x-2  font-medium">
                    {/* <ParticipateButton setMenuOpen={setMenuOpen} />
                    <NavLink className="rounded-[100%] p-1 bg-[#57B17E] text-white text-[25px] " to="/">
                        <AiOutlineUserAdd />
                    </NavLink> */}
                </div>


                {/****** Mobile Menu Button ******/}
                <div
                    className={"menu-burger up-down flex lg:hidden ml-auto " + (menuOpen ? 'menu-open' : '')}
                    onClick={() => setMenuOpen((current) => { return !current })}
                >
                    <div className="bar" />
                    <div className="bar" />
                    <div className="bar" />
                </div>

                {/******* Mobile Menu *******/}

                <div className='mobile-menu lg:hidden'>
                    <div className={'absolute top-0 right-0 z-1 w-full h-screen transition-colors duration-500 '
                        + (menuOpen ? 'translate-x-0 bg-[#00000034]' : 'translate-x-full bg-transparent')}
                        onClick={() => setMenuOpen(false)}
                    ></div>

                    <ul className={'mobile-menu overflow-auto absolute top-0 z-10 right-0 flex flex-col justify-center items-center text-lg '
                        + ' w-full h-screen sm:w-2/3 md:w-1/2 bg-white transition-transform duration-500 '
                        + (menuOpen ? 'translate-x-0' : 'translate-x-full')}>
                        {t("nav.links", { returnObjects: true }).map((item) => {
                            if (item.subMenu) {
                                return <li className='flex flex-col justify-center w-full'>
                                    <button className={"space-x-2 w-full text-center border-b-2 border-t-2 py-4 " + (pathname.includes(item.url) ? ' poi  text-[#F4AE3F] font-semibold active-link w-full text-center border-b-2 border-t-2 py-4 ' : "")} to={item.url}
                                        onClick={() => {
                                            setDropDown(!dropDown)
                                        }}
                                    >
                                        <span>{item.text}</span>
                                        {dropDown ?
                                            <FontAwesomeIcon icon={faCaretUp} />
                                            :
                                            <FontAwesomeIcon icon={faCaretDown} />}
                                    </button>
                                    {dropDown ? <div className={'  bottom-0 left-0  bg-[#E1F4FC] px-5 py-3 '
                                        + ' flex flex-col '}>
                                        {item.subMenu.map((link, i) => {
                                            return <a className={'text-[#000] hover:text-[#F4AE3F] text-justify py-2 '
                                                + (i !== 0 ? " border-t-2 border-[#F4AE3F] " : "")} href={link.url}
                                                target={link.newTab === "true" ? "_blank" : "_self"}
                                            >
                                                {link.text}
                                            </a>
                                        })}
                                    </div> : ""}
                                </li>
                            }
                            else {
                                return <li className='flex justify-center w-full'>
                                    <NavLink className={({ isActive }) => {
                                        return "w-full text-center border-b-2 border-t-2 py-4" + (isActive ? 'poi  text-[#F4AE3F] font-semibold active-link w-full text-center border-b-2 border-t-2 py-4 ' : '')
                                    }} to={item.url}
                                        onClick={() => setMenuOpen(false)}
                                    >{item.text}</NavLink>
                                </li>
                            }

                        })}

                        {/* <li className='absolute top-8 left-6'>
                            <ParticipateButton setMenuOpen={setMenuOpen} />
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Nav;