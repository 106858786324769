import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MainHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClipboardList, faHotel, faImages, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import headerImages from './headerImages.json'

function MainHeader() {
    library.add(fas, faCalendarDays, faHotel, faClipboardList, faRightToBracket)
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const isFr = i18n.language === 'fr';
    const isEn = i18n.language === 'en';

    const settings = {
        infinite: true,
        centerPadding: '0px',
        slidesToShow: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <>
            {/* <div className="relative bg-[#EEF9FD]">
                <img className="absolute top-0 left-0 z-10" src="/images/header/star.png" alt="" />
                <img className="absolute top-0 left-0 w-full h-full object-cover" src="/images/header/layer_shape.png" alt="" />
                <img className="absolute top-0 right-0 translate-y-[50%] z-10" src="/images/header/star2.png" alt="" />
                <img className="absolute top-0 right-0 translate-x-[-100%] z-10" src="/images/header/star3.png" alt="" />
                <div className="flex flex-col items-center justify-center px-2 text-[#5BB382] ">
                    <div className="flex flex-col items-start">
                        <span className="text-center pt-5 font-[moroccan-font] text-[20px] sm:text-[22px] md:text-[28px] lg:text-[35px] ">تحت الرعاية السامية لصاحب الجلالة الملك محمد السادس</span>
                        <span className="text-center font-serif text-[16px] md:text-[18px] lg:text-[29px] ">Sous le Haut-Patronage de Sa Majesté le Roi Mohamed VI</span>
                    </div>
                </div>
                <div className="relative flex justify-center items-center h-full sm:h-[160px] px-5 sm:pl-10 py-4 ">
                    <img className="h-full" src="/images/header/test.png" data-aos="zoom-out" data-aos-duration="500" />
                    <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-full" src="/images/header/edition_bg.png" />
                </div>
            </div> */}
            <div className="autoimgwidth">
                {isEn && <img src="/images/header/header-en.jpg" alt="" />}
                {isFr && <img src="/images/header/header-fr.jpg" alt="" />}
                {isRtl && <img src="/images/header/header-ar.jpg" alt="" />}
            </div>
            <header className="relative w-full h-[calc(100vh-110px)] mb-32 "
                data-aos="fade-up" data-aos-duration="500"
            >
                {/******* Background Slide ********/}
                <Slider className="header-slider w-full h-full" {...settings}>
                    {headerImages.map((item) => {
                        return <div className="relative h-full">
                            <div className="absolute w-full h-full bg-[#0000003b]"></div>
                            <img className="object-cover object-center h-full w-full" src={"/images/header/" + item} />
                        </div>
                    })}


                </Slider>

                {/******* Header Title *******/}
                <h1 className={"absolute top-14 flex flex-col text-center w-full "
                    + " md:w-auto md:top-14 md:left-8 md:text-start text-[25px] "
                    + " sm:text-[35px] md:text-[40px] lg:text-[50px] xl:text-[60px] "
                    + " font-[GothamBold] text-white drop-shadow-md"}
                    data-aos="fade-right" data-aos-delay='500' data-aos-duration="700"
                >
                    {/* <span>From COP 27 To Cop 28</span> */}
                    {/* <span className="mt-2 md:ml-10 px-2 text-[16px] sm:text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px]">
                        MEDCOP driving climate action in the Mediterranean at local and regional levels.
                    </span> */}
                </h1>

                <div className="absolute bottom-[30%] left-0 w-full flex flex-col items-center">

                    <p className={"flex flex-col items-center w-[100%] md:w-[70%] px-20 py-8 bg-[#0000005d] "
                        + " lg:text-[22px] xl:text-[25px] font-[GothamMedium] text-white text-center"}
                        data-aos="fade-up" data-aos-delay='1000' data-aos-duration="700" data-aos-offset="00"
                    >
                        <h1 className={"flex flex-col text-center w-full mb-10 "
                            + " md:w-auto md:top-14 md:left-8 md:text-start text-[25px] "
                            + " sm:text-[35px] md:text-[40px] lg:text-[50px] xl:text-[60px] "
                            + " font-[GothamBold] text-white drop-shadow-md"}
                            data-aos="fade-right" data-aos-delay='500' data-aos-duration="700"
                        >
                            <span className={isRtl ? 'rtl-text' : ''}>{t("home.header.title")}</span>
                            {/* <span className="mt-2 md:ml-10 px-2 text-[16px] sm:text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px]">
                        MEDCOP driving climate action in the Mediterranean at local and regional levels.
                    </span> */}
                        </h1>
                        <span className={isRtl ? 'rtl-text' : ''}>{t("home.header.sub title")}</span>
                    </p>
                </div>

                {/******* Header Icons ********/}
                <div className="absolute bottom-0 left-0 w-full flex flex-col items-center ">
                    <ul className={"flex flex-wrap md:flex-nowrap w-[80%] translate-y-[50%] font-[GothamBook] text-[16px] sm:text-[20px] "
                        + " md:text-[18px] lg:text-[28px] font-medium text-center justify-between overflow-hidden "}
                    >
                        {t("home.header.buttons", { returnObjects: true }).map((item) => {
                            return (
                                <li className="flex items-center justify-center w-[calc(50%-4px)] md:w-[calc(25%-5px)] mt-2  bg-[#57B17E] hover:bg-[#3d7187] transition-colors duration-200"
                                    data-aos="fade-right"
                                >
                                    <a href={item.url} className="flex items-center justify-center space-x-4 py-6 px-4">
                                        <FontAwesomeIcon icon={item.icon} color="#fff" />
                                        <span className="inline-block text-[#fff]">{item.text}</span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </header>
        </>
    )
}

export default MainHeader;