import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Sponsors.css';
import Title from "../Title/Title";
import sponsorsList from '../../pages/SponsorsPage/sponsorsList.json'
import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Sponsors() {
    const { t } = useTranslation();

    const institutionalSetting = {
        centerMode: false,
        infinite: true,
        centerPadding: '60px',
        slidesToShow: 3,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                }
            }
        ]
    }

    const settings = {
        centerMode: false,
        infinite: true,
        centerPadding: '60px',
        slidesToShow: 4,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                }
            }
        ]
    };


    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <div>
            <div className="sponsors flex flex-col items-center justify-center gap-8 mt-10 mb-16"
                data-aos="fade-up"
            >
                <Title name={t("home.sponsors.institutional")}></Title>
                <Slider className='flex items-center justify-center w-[95%] gap-20 mt-[35px]' {...institutionalSetting}>
                    {sponsorsList.institutional.map((item, i) => {
                        return (<div key={i} className="slide-child h-[200px]">
                            <img className="h-full" src={"/images/sponsors/" + item.name} alt="" />
                        </div>)
                    })}
                </Slider>


            </div>

            <div className="sponsors flex flex-col items-center justify-center gap-8 mt-10 mb-16"
                data-aos="fade-up"
            >
                <Title name={t("home.sponsors.partners")}></Title>
                <Slider className='flex items-center justify-center w-[95%] gap-20 mt-[35px]' {...settings}>
                    {sponsorsList.normal.map((item, i) => {
                        return (<div key={i} className="slide-child h-[100px] max-w-[90%]">
                            <img className="max-h-full" src={"/images/sponsors/" + item.name} alt="" />
                        </div>)
                    })}
                </Slider>


            </div>

            <div className="sponsors flex flex-col items-center justify-center gap-8 mt-10 mb-16"
                data-aos="fade-up"
            >
                <Title name={t("home.sponsors.sponsors")}></Title>
                <Slider className='flex items-center justify-center w-[95%] gap-20 mt-[35px]' {...settings}>
                    {sponsorsList.sponsors.map((item, i) => {
                        return (<div key={i} className="slide-child h-[100px] max-w-[90%]">
                            <img className="max-h-full" src={"/images/sponsors/" + item.name} alt="" />
                        </div>)
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default Sponsors;
