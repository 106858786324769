import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import MediaComponent from "../../Components/MediaComponent/MediaComponent";
import Title from "../../Components/Title/Title";
import PressArticles from "../../Components/PressArticles/PressArticles";

function SideEvents({ className = "" }) {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const event = t("sideEventContent", { returnObjects: true });
    return (

        <div className="pb-20">
            <Header title={t("nav.links", { returnObjects: true })[6].text} bgImg={"/images/hotels/tangier-city.jpg"} />

            <div className={isRtl ? 'rtl-container' : ''}>
                <div>
                    <div className={'flex flex-col gap-[80px] mt-20 px-[10%] ' + className}>
                        <p className={isRtl ? 'rtl-text' : ''}>
                            <div className={'Hotel_4 flex flex-wrap items-center justify-center '}>
                                <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[50%] object-cover object-center ' src="/images/SideEvents/side-event.jpg" alt="" />
                                <div className='aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[50%] py-5 p-5 sm:p-10'>
                                    <h1 className={'text-[30px] text-justify text-[#1b5b92]' + (isRtl ? " font-[arab] " : " font-[GothamBoldItalic] ")}  >
                                        {event.title}
                                    </h1>
                                    <p className={'text-[18px] text-justify' + (isRtl ? " font-[arab] " : "  font-[GothamBook] ")}>
                                        {event.text}
                                    </p>
                                    <div className='flex gap-5'>
                                        {/* view site button */}
                                        <a target="_blank" href="https://ufmsecretariat.org/event/3rd-energy-climate-business-forum/" className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4' >
                                        {event.visit}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SideEvents;