import "./bookHotel.css";
import Header from "../Header/Header";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

function BookForm() {
    const [transfer, setTransfer] = useState(false);
    const [dietary, setDietary] = useState(false);
    let params = useParams();
    const { t, i18n } = useTranslation();
    const hotelsList = t("hotels.list", { returnObjects: true });
    let hotel = hotelsList.filter(item => item.url === params.name)[0];
    const form = t("booking.form", { returnObjects: true });
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        window.location.href = '/';
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        "firstName": "",
        "lastName": "",
        "companyName": "",
        "hotelName": hotel.name,
        "roomOption": "",
        "checkIn": "",
        "checkOut": "",
        "phone": "",
        "email": "",
        "pickuptransfer": "",
        "roadtrip": "",
        "fromAiroport": "",
        "rideprefer": "",
        "transportation_arriveTime": "",
        "transportation_departureTime": "",
        "transportation_ticketInfo": "",
        "spacial_dietary_veg": false,
        "spacial_dietary_gluten": false,
        "spacial_dietary_lactos": false,
        "spacial_dietary_nut": false,
        "spacial_dietary_shellfish": false,
    });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const handleRadioChange = (event) => {
        const { name, value } = event.target;


        if (name === "roomOption" || name === "roadtrip" || name === "fromAiroport" || name === "rideprefer") {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [name]: value
            }));
        } else if (name === "pickuptransfer") {
            setTransfer(value === "yes");
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [name]: value
            }));
        }
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: checked
        }));
    };
    const handleSubmit = event => {
        setIsLoading(!isLoading);
        event.preventDefault();
      // TODO: Implement API call to submit form data
      fetch('https://api.medcop.ma/api/Reservation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
    
        console.log(data);
        handleOpen();
        // TODO: Handle successful response
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
        handleOpen();
        // TODO: Handle error
      });
        
       
      
      };
    return (
        <div className='book-form'>
            <Header title={t("booking.title")} bgImg="/images/hotels/tangier-city.jpg"></Header>
            <div className="wrapper">
                <div className="form-left bookingcss">
                    <img id='img-booking' src={"/images/hotels/" + hotel.image} alt="" />
                    <div id='booking-left-content'>
                        <h2 className="text-uppercase">{hotel.name}</h2>
                        {/* <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et molestie ac feugiat sed. Diam volutpat commodo.
                        </p>
                        <p className="text">
                            <span>Sub Head: </span>
                            Vitae auctor eu augudsf ut. Malesuada nunc vel risus commodo viverra. Praesent elementum facilisis leo vel.
                        </p> */}
                    </div>
                </div>
                <form className="form-right" onSubmit={handleSubmit}>
                    <h2 className="text-uppercase">{form.title}</h2>
                    <div className="row">
                        <div className="col-sm-6 mb-3">
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.firstName}</label>
                            <input type="text" name="firstName" id="firstName" className="input-field" value={formValues.firstName} onChange={handleInputChange} />
                        </div>
                        <div className="col-sm-6 mb-3">
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.lastName}</label>
                            <input type="text" name="lastName" id="lastName" className="input-field" value={formValues.lastName} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.companyName}</label>
                        <input type="text" className="input-field" name="companyName" required value={formValues.companyName} onChange={handleInputChange} />
                    </div>
                    <div className="mb-5">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.hotelName}</label>
                        <input type="text" value={formValues.hotelName} onChange={handleInputChange} disabled className="input-field" name="hotelName" required />
                    </div>

                    <div className="mb-3" style={{ display: "flex", alignItems: "center", flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                        <label className="radio radio-btn">{form.fields.singleRoom}
                            <input
                                type="radio"
                                name="roomOption"
                                value="singleroom"
                                checked={formValues.roomOption === "singleroom"}
                                onChange={handleRadioChange}
                            />
                            <span className="radio" />
                        </label>
                        <label className="radio radio-btn" style={{ marginLeft: "20px" }} >{form.fields.doubleRoom}
                            <input
                                type="radio"
                                name="roomOption"
                                value="doubleroom"
                                checked={formValues.roomOption === "doubleroom"}
                                onChange={handleRadioChange}
                            />
                            <span className="radio" />
                        </label>
                    </div>

                    <div className="mb-3">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.checkIn}</label>
                        <input type="date" className="input-field" name="checkIn" value={formValues.checkIn} onChange={handleInputChange}  />
                    </div>

                    <div className="mb-3">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.checkOut}</label>
                        <input type="date" className="input-field" name="checkOut" value={formValues.checkOut} onChange={handleInputChange}  />
                    </div>

                    <div className="mb-3">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.phone}</label>
                        <input type="tel" className="input-field" name="phone" value={formValues.phone} onChange={handleInputChange} required />
                    </div>

                    <div className="mb-3">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.fields.email}</label>
                        <input type="email" className="input-field" name="email" value={formValues.email} onChange={handleInputChange} required />
                    </div>

                    <div className="mb-3" style={{ display: "flex", alignItems: "center", gap: "20px", flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>
                            {form.transportation.mainQuestion.text}
                        </label>
                        <label className="radio radio-btn">
                            {form.transportation.mainQuestion.answer1}
                            <input
                                type="radio"
                                name="pickuptransfer"
                                checked={formValues.pickuptransfer === "yes"}
                                onChange={handleRadioChange}
                                value="yes"
                            />
                            <span className="radio" />
                        </label>
                        <label className="radio radio-btn" style={{ marginLeft: "20px" }}>
                            {form.transportation.mainQuestion.answer2}
                            <input
                                type="radio"
                                name="pickuptransfer"
                                checked={formValues.pickuptransfer === "no"}
                                onChange={handleRadioChange}
                                value="no"
                            />
                            <span className="radio" />
                        </label>
                    </div>

                    {transfer ? <div style={{ borderWidth: "1px", borderRadius: "5px", width: "100%", padding: "10px" }}>
                        <div className="mb-5" style={{ display: "flex", alignItems: "center", gap: "20px", flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                            <label className="radio radio-btn">{form.transportation.question1.answer1}
                                <input type="radio"
                                    name="roadtrip"
                                    checked={formValues.roadtrip === "One way"}
                                    onChange={handleRadioChange}
                                    value="One way"
                                    defaultChecked />
                                <span className="radio" />
                            </label>
                            <label className="radio radio-btn" style={{ marginLeft: "20px" }} >{form.transportation.question1.answer2}
                                <input type="radio"
                                    name="roadtrip"
                                    checked={formValues.roadtrip === "Round trip"}
                                    onChange={handleRadioChange}
                                    value="Round trip"
                                />
                                <span className="radio" />
                            </label>
                        </div>

                        <div className="mb-5" style={{ display: "flex", alignItems: "center", gap: "20px", flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.transportation.question2.text}</label>
                            <label className="radio radio-btn">{form.transportation.question2.answer1}
                                <input type="radio"
                                    name="fromAiroport"
                                    checked={formValues.fromAiroport === "Casablanca"}
                                    onChange={handleRadioChange}
                                    value="Casablanca"
                                    defaultChecked />
                                <span className="radio" />
                            </label>
                            <label className="radio radio-btn" style={{ marginLeft: "20px" }} >{form.transportation.question2.answer2}
                                <input type="radio"
                                    name="fromAiroport"
                                    checked={formValues.fromAiroport === "Rabat"}
                                    onChange={handleRadioChange}
                                    value="Rabat" />
                                <span className="radio" />
                            </label>
                            <label className="radio radio-btn" style={{ marginLeft: "20px" }} >{form.transportation.question2.answer3}
                                <input type="radio"
                                    name="fromAiroport"
                                    checked={formValues.fromAiroport === "Tangier"}
                                    onChange={handleRadioChange}
                                    value="Tangier"
                                />
                                <span className="radio" />
                            </label>
                        </div>

                        <div className="mb-5" style={{ display: "flex", alignItems: "center", gap: "20px", flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.transportation.question3.text}</label>
                            <label className="radio radio-btn">{form.transportation.question3.answer1}
                                <input type="radio"
                                    name="rideprefer"
                                    checked={formValues.rideprefer === "Shuttle  ride of 5 people"}
                                    onChange={handleRadioChange}
                                    value="Shuttle  ride of 5 people"
                                    defaultChecked />
                                <span className="radio" />
                            </label>
                            <label className="radio radio-btn" style={{ marginLeft: "20px" }} >{form.transportation.question3.answer2}
                                <input type="radio"
                                    name="rideprefer"
                                    checked={formValues.rideprefer === "TGV"}
                                    onChange={handleRadioChange}
                                    value="TGV" />
                                <span className="radio" />
                            </label>
                        </div>

                        <div className="mb-5">
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.transportation.arriveTime}</label>
                            <input type="date" className="input-field" name="transportation_arriveTime" value={formValues.transportation_arriveTime} onChange={handleInputChange} />
                        </div>

                        <div className="mb-5">
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.transportation.departureTime}</label>
                            <input type="date" className="input-field" name="transportation_departureTime" value={formValues.transportation_departureTime} onChange={handleInputChange} />
                        </div>

                        <div className="mb-5">
                            <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>{form.transportation.ticketInfo}</label>
                            <input type="text" className="input-field" name="transportation_ticketInfo" value={formValues.transportation_ticketInfo} onChange={handleInputChange} />
                        </div>
                    </div> : ""}


                    <div className="mb-5">
                        <label style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>
                            {form["Spacial-dietary"].mainQuestion.text}
                        </label>
                        <div className='special-dietary' style={{ flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}>
                        <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer1}
                                <input
                                    type="checkbox"
                                   
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer2}
                                <input
                                    type="checkbox"
                                    name="spacial_dietary_veg"
                                    checked={formValues.spacial_dietary_veg}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer3}
                                <input
                                    type="checkbox"
                                    name="spacial_dietary_gluten"
                                    checked={formValues.spacial_dietary_gluten}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer4}
                                <input
                                    type="checkbox"
                                    name="spacial_dietary_lactos"
                                    checked={formValues.spacial_dietary_lactos}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer5}
                                <input
                                    type="checkbox"
                                    name="spacial_dietary_nut"
                                    checked={formValues.spacial_dietary_nut}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="option">
                                {form["Spacial-dietary"].mainQuestion.answer6}
                                <input
                                    type="checkbox"
                                    name="spacial_dietary_shellfish"
                                    checked={formValues.spacial_dietary_shellfish}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                            </label>
                          
                        </div>
                    </div>


                    <div className="mb-3" style={{ display: "flex", alignItems: "center", gap: "20px", justifyContent: i18n.language === "ar" ? "right" : "left" }}>
                        <label className="option"  >
                            <span>{form.agree}</span>
                            <input type="checkbox" defaultChecked />
                            <span className="checkmark" />
                        </label>
                    </div>

                    <div className="form-field">
                        <input type="submit" defaultValue="Register" className="register" name="register" value={form.book} />
                    </div>
                </form>
            </div>
            {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>You have been registered successfully</h2>
            <p>We will send you Soon an Email with all Information</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}
      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }
    </div>
    )
}

export default BookForm;
