import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import Title from '../Title/Title';
import AOS from "aos";
import 'aos/dist/aos.css';
import Modal from '../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Testimonials() {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const testimonialsList = t("home.testimonials.content", { returnObjects: true })

    const [more, setMore] = useState(false);
    const [moreText, setText] = useState("");

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    const settings = {
        centerMode: false,
        infinite: true,
        centerPadding: '60px',
        slidesToShow: 3,
        dots: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                },
            },
        ]
    }

    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <div className={isRtl ? 'rtl-text' : ''}>
                <div id='testimonials' className='flex flex-col mt-5'
                >
                    <div className="flex justify-center my-[40px]"
                        data-aos="fade-up"
                    >
                        <Title name={t("home.testimonials.title")}></Title>
                    </div>
                    <div className='flex justify-around mt-8'
                        data-aos="fade-up" data-aos-delay="500"
                    >
                        <Slider initialSlide={1} className='w-full mt-16 mb-10' {...settings}>
                            {testimonialsList.map((item, i) => {
                                return (<>
                                    <div key={i} className={'testimonial ml-10 mr-10 mb-5 h-[400px] md:h-[500px] lg:h-[400px] xl:h-[400px] '
                                        // <div key={i} className={'testimonial aspect-[0.9/1] xl:aspect-square ml-10 mr-10 mb-5 '
                                        + (i === 1 ? " slick-center " : "")} >
                                        <div className='flex items-end h-[50%] bg-[#51BDEB] xl:px-[40px] pb-[40px] md:pb-[50px]'>
                                            <div className='font-[GothamBook] text-white text-center text-[16px] md:text-[22px] lg:text-[14px] px-4'>
                                                {item.text.length > 80 ? <div className='flex flex-col items-center justify-center '>
                                                    <span>{item.text.slice(0, 80) + "...\""} </span>
                                                    <button className='text-[#eeeeee] font-bold'
                                                        onClick={() => {
                                                            setMore(() => true);
                                                            setText(() => <div className='flex flex-col items-center gap-5'>
                                                                <Title name={item.name} />
                                                                <p>{item.text}</p>
                                                            </div>)
                                                        }}>Read More <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
                                                </div> : item.text}
                                            </div>
                                        </div>
                                        <div className='relative flex flex-col gap-3 h-[50%] pt-[30px] md:pt-[40px] bg-white shadow-lg '>
                                            <img className="absolute top-0 left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[50%] w-20 md:w-20"
                                                src={"/images/testimonials/" + item.image} alt="" />
                                            <div className=''>
                                            </div>
                                            <h3 className='font-[GothamMedium] text-center text-[16px] md:text-[20px] lg:text-[17px]'>{item.name}</h3>
                                            <p className='text-center text-[16px] md:text-[20px] lg:text-[16px] px-1'>{item.role}</p>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </Slider>
                    </div >
                    <Modal setVisible={setMore} visible={more}>{moreText}</Modal>
                </div >
            </div>
        </div>
    )
}

export default Testimonials;