import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import MediaComponent from "../../Components/MediaComponent/MediaComponent";
import Title from "../../Components/Title/Title";
import PressArticles from "../../Components/PressArticles/PressArticles";

function GIZ({ className = "" }) {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const event = t("sideEventContent", { returnObjects: true });
    return (

        <div className="pb-20">
            <Header title={t("nav.links", { returnObjects: true })[6].text} bgImg={"/images/hotels/tangier-city.jpg"} />

            <Title className=" text-[#1B5B92] my-9 " name={t("nav.links", { returnObjects: true })[6].subMenu[6].text} />
            <div className="flex flex-col items-center ">
                <img className="w-10/12" src="/pdf/GIZ.jpg" alt="" />
            </div>

        </div>

    );
}

export default GIZ;