import "./participer.css"
import Header from "../../Components/Header/Header";
import { fetchData } from "../../services/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Participer() {

    const {t} = useTranslation();

    return (
        <div >
            <Header title={t("signupPage.title")} bgImg="/images/about-us/tangier-night.jpg"></Header>
            <div className="p-5 mt-16">
                <div className='flexdisheadertext'>
                <div className='bigfont' >
                    <span className='meeting'>{t("about.meeting.title")}</span>
                    <div className='meeting2'>
                        <div className='line'></div>
                        <span className='date'>{t("about.meeting.date")}</span>
                        <div className='line'></div>
                    </div>
                </div>
                </div>
                <div className='flexdisheadertext mt-5'>
                    <h2>{t("signupPage.participateNow")}</h2>
                </div>
                <div className='flexdisheadertext participate-links'>
                        <Link className='btnyellow' to="/participer/participant">{t("nav.signup.links", {returnObjects:true})[0].text}</Link>
                        <Link className='btntransparent' to="/participer/exposant">{t("nav.signup.links", {returnObjects:true})[1].text}</Link>
                        <Link className='btnyellow' to="/participer/intervenant">{t("nav.signup.links", {returnObjects:true})[2].text}</Link>
                </div>
            </div>
        </div>
    )
}

export default Participer;