import Media from "../../Bootstrap Components/Media/Media";
import MainHeader from "../../Components/MainHeader/MainHeader";
import OutNow from "../../Components/OutNow/OutNow";
import Sponsors from "../../Components/Sponsors/Sponsors";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Organizers from "../../Components/Organizers/Organizers";
import MediaComponent from "../../Components/MediaComponent/MediaComponent";
import "./Home.css";
import { useTranslation } from 'react-i18next';
import StickyButton from "../../Components/EventParralleles/EventP";

function Home() {
    const { t,i18n } = useTranslation();
    const isRtl = i18n.language === 'ar';
    return (
        <>
              {/* <StickyButton /> */}
            <MainHeader></MainHeader>
            <div>
                <MediaComponent className="pt-5" />
                <div className="bootstrap">
                    <Media></Media>
                </div>
                <Organizers></Organizers>
                <Sponsors></Sponsors>
                <Testimonials></Testimonials>
                <OutNow></OutNow>
            </div>
            <div>
                <a
                    href="https://www.google.com/maps/dir/Tanger+Ville+Railway+Station,+Tangier+90000/Hotel+Andalucia+Golf,+Route+De,+Tangier+90000/Hilton+Tangier+Al+Houara+Resort+%26+Spa%D8%8C+Km+19.8,+National+Route+1+Tangier-Asilah,+Tangier+90100%E2%80%AD%E2%80%AD/Farah+Hotel+Tanger,+El+Ghandouri%D8%8C+Tangier+90000%E2%80%AD%E2%80%AD/@35.7141659,-5.8403106,11.37z/data=!4m26!4m25!1m5!1m1!1s0xd0b809c6e30ee7b:0x76d10e4ea25f6dcc!2m2!1d-5.7858751!2d35.7715827!1m5!1m1!1s0xd0c77d46b673909:0xa4be6d8333df786b!2m2!1d-5.8474179!2d35.7779125!1m5!1m1!1s0xd0b8fc9fe02d907:0x1c98273f308ecd7e!2m2!1d-5.9658372!2d35.6674645!1m5!1m1!1s0xd0c7f93a1bfa4cb:0xbeeec552a5c7968a!2m2!1d-5.7538959!2d35.7929706!3e0?hl=en " target="_blank">
                    <img className="" src="images/Gare Tanger Ville-1.png" alt="" />
                </a>
            </div>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m46!1m12!1m3!1d103645.90644296157!2d-5.942144756297829!3d35.72782749420863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m31!3e0!4m5!1s0xd0b809c6e30ee7b%3A0x76d10e4ea25f6dcc!2sGare%20Tanger%20Ville%2C%20Tangier!3m2!1d35.771582699999996!2d-5.7858751!4m5!1s0xd0b8fc9fe02d907%3A0x1c98273f308ecd7e!2sHilton%20Tangier%20Al%20Houara%20Resort%20%26%20Spa%D8%8C%20Km%2019.8%2C%20National%20Route%201%20Tangier-Asilah%2C%20Tangier%2090100!3m2!1d35.6674645!2d-5.9658372!4m5!1s0xd0c7f93a1bfa4cb%3A0xbeeec552a5c7968a!2sH%C3%B4tel%20Farah%20Tanger%2C%20El%20Ghandouri%D8%8C%20Tangier!3m2!1d35.7929706!2d-5.7538959!4m5!1s0xd0c77d46b673909%3A0xa4be6d8333df786b!2sHotel%20Andalucia%20Golf%20Tanger%2C%20Tangier!3m2!1d35.7779125!2d-5.8474179!4m5!1s0xd0b809cc2c3afb9%3A0x3695c03d0c508f8a!2sRoyal%20Tulip%20City%20Center%2C%20Route%20de%20Malabata%2C%20Tangier!3m2!1d35.7732497!2d-5.7838055!5e0!3m2!1sen!2sma!4v1684763482964!5m2!1sen!2sma" width="600" height="450" style={{border:0, width: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </>
    )
}

export default Home;