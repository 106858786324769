import '../bootstrap.css';
import './Media.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGraduationCap, faBuildingColumns, faEarthAfrica } from '@fortawesome/free-solid-svg-icons';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

function Media() {

    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const speakers = t("speakersList", { returnObjects: true });
    let count = 0;
    speakers.forEach(el => {
        if(el.image === ".jpg"){
            count++;
        }
    });
    console.log("speaker count: ",count);

    const settings = {
        centerMode: false,
        infinite: false,
        centerPadding: '60px',
        slidesToShow: 3,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                }
            }
        ]
    };


    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])
    return (

        <div style={{ padding: '0px', marginTop: '150px' }} className="container"
            data-aos="fade-up"
        >

            <div style={{ position: "relative", padding: '0 50px' }} className='flexdisheadertext'

            >
                <div className={isRtl ? 'rtl-container' : ''}>
                    <div className='bigfont' >
                        <span className='meeting'>{t("about.meeting.title")}</span>
                        <div className='meeting2'>
                            <div className='line'></div>
                            <span className='date'>{t("about.meeting.date")}</span>
                            <div className='line'></div>
                        </div>
                    </div>
                </div>
                <img
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        translate: '-50% 0',
                        height: '150%',
                        opacity: 0.5,
                    }}
                    src="/images/circle-green.png" alt=""
                    data-aos="fade-right" data-aos-duration="700"
                />
                <img style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    translate: '50% 0',
                    height: '150%',
                    opacity: 0.5,
                }}
                    src="/images/circle-orange.png" alt=""
                    data-aos="fade-left" data-aos-duration="700"
                />
            </div>
            {/* <div style={{ marginTop: '30px' }} className='flexdisheadertext'>
                <h3>Participer Maintenant</h3>
            </div>
            <div className='flexdisheadertext' style={{ marginBottom: "45px" }}>
                <span className='btnyellow'>
                    <Link to="/participer/participant">Participant</Link>
                </span>
                <span className='btntransparent ml-1'>
                    <Link to="/participer/exposant">Exposant</Link>
                </span>
                <span className='btnyellow ml-1'>
                    <Link to="#">Intervenant</Link>
                </span>
            </div> */}

            <div style={{ marginTop: '70px' }} className='row text-center'
                data-aos="fade-up" data-aos-duration="700"
            >
                <div className="col-sm-3 col-xs-6">
                    <div className='icoparent'>
                        {/* <FontAwesomeIcon icon={faUser} className='icocls' /> */}
                        <img src="/images/media/speaker2.svg" alt="" />
                        <p>{t("home.media.iconNumbers.speakers")}</p>
                        <h1>+100</h1>
                    </div>
                </div>
                <div className="col-sm-3 col-xs-6"><div className='icoparent'>
                    {/* <FontAwesomeIcon icon={faGraduationCap} className='icocls' /> */}
                    <img src="/images/media/cities1.svg" alt="" />
                    <p>{t("home.media.iconNumbers.cities & Regions")}</p>
                    <h1>+100</h1>
                </div></div>
                <div className="col-sm-3 col-xs-6"><div className='icoparent'>
                    {/* <FontAwesomeIcon icon={faBuildingColumns} className='icocls' /> */}
                    <img src="/images/media/visiteurs1.svg" alt="" />
                    <p>{t("home.media.iconNumbers.visitors")}</p>
                    <h1>+1000</h1>
                </div></div>
                <div className="col-sm-3 col-xs-6"><div className='icoparent'>
                    {/* <FontAwesomeIcon icon={faEarthAfrica} className='icocls' /> */}
                    <img src="/images/media/participants1.svg" alt="" />
                    <p>{t("home.media.iconNumbers.participants")}</p>
                    <h1>+500</h1>
                </div></div>
            </div>

            <div style={{ marginTop: '70px', marginBottom: '40px' }} className="row"
                data-aos="zoom-in"
            >
                <div className='flexdisheadertext'>
                    <span className='headerstyle clr' >
                        {t("home.media.GeneralObjective.title")}
                    </span>
                </div>
                <div className='flexdisheadertext mt-3'>
                    <div className='descstyle'>
                        <p className='clr'>
                            {t("home.media.GeneralObjective.description")}
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: '30px' }} className="MediaTitle row pt-4"
                data-aos="fade-up"
            >
                <h1 className="flex flex-col items-center text-[35px] tracking-wider">
                    <span>{t("home.media.mediaTitle")}</span>
                    <div className="w-[20%] h-2 bg-[#51bdeb3c]"></div>
                </h1>
            </div>
            <div className="row media-videos" style={{ padding: "0 10%" }}>
                <div className="col-12 col-md-12 col-lg-12  mt-5  px-5"
                    data-aos="fade-right"
                >

                    <iframe className='main-vid' style={{ width: '100%', height: "350px" }} src="https://www.youtube.com/embed/OqxV64qACLE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
                <div className="col-xs-6 col-md-6 col-lg-6 mt-5 col-12 px-5"
                    data-aos="fade-up"
                >

                    <iframe style={{ width: '100%', height: "350px" }} src="https://www.youtube.com/embed/t3zuCs89Kf4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
                <div className="col-xs-6 col-md-6 col-lg-6 mt-5 col-12 px-5"
                    data-aos="fade-left"
                >

                    <iframe style={{ width: '100%', height: "350px" }} src="https://www.youtube.com/embed/1fRdSVnhS6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
                {/* <div style={{ marginTop: '50px', marginBottom: '50px' }} className="row pt-4"
                    data-aos="zoom-in"
                >
                    <h1 className="flex flex-col items-center text-[35px] font-bold tracking-wider">
                        <span>Confirmed speakers</span>
                        <div className="w-[20%] h-2 bg-[#51bdeb3c]"></div>
                    </h1>
                </div> */}
                <div className="row" style={{ marginBottom: "40px", marginLeft: 0, marginRight: 0 }}
                    data-aos="fade-up"
                >

                    <div style={{ marginBottom: '30px', marginTop: '50px' }} className="MediaTitle row pt-4"
                        data-aos="fade-up"
                    >
                        <h1 className="flex flex-col items-center text-[35px] tracking-wider">
                            <span>{t("speakers")}</span>
                            <div className="w-[20%] h-2 bg-[#51bdeb3c]"></div>
                        </h1>
                    </div>
                    <Slider className='speakers' {...settings}>
                        {speakers.map((speaker, index) => {
                            if (index % 2 === 0) {


                                return <div className="speaker" style={{ margin: "15px !important" }}>
                                    <div className="overcard">
                                        <div className="card cardd" style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '25px', background: '#E4F3F8' }}>
                                            <img className="circle-img" src={speaker.image !== ".jpg" ? "/images/speakers/" + speaker.image : "/images/person.png"} alt="Card image" />
                                            <div className="card-body cardbodypad" style={{ textAlign: 'center', paddingTop: '40px' }}>
                                                <h4 className="card-title">
                                                    {speakers[index].name}
                                                </h4>
                                                <p className="card-text">
                                                    {speakers[index].role}. <br />
                                                    {speakers[index].place}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        speakers[index + 1] ?
                                            <div className="overcard">
                                                <div className="card cardd" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '25px', background: '#E4F3F8' }}>
                                                    <img className="circle-img" src={speakers[index + 1].image !== ".jpg" ? "/images/speakers/" + speakers[index + 1].image : "/images/person.png"} alt="Card image" />
                                                    <div className="card-body cardbodypad" style={{ textAlign: 'center', paddingTop: '40px' }}>
                                                        <h4 className="card-title">
                                                            {speakers[index + 1].name}
                                                        </h4>
                                                        <p className="card-text">
                                                            {speakers[index + 1].role} <br />
                                                            {speakers[index + 1].place}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> : <div className="overcard" ></div>
                                    }
                                </div>
                            }
                        })}
                    </Slider>
                </div>

            </div>
        </div>
    );
}

export default Media;
