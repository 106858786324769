import React, { useState, useTransition } from 'react';
import Title from '../Title/Title';
// import listObjectifs from './ListObjectifs.json';
import './objectifs.css'
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';

function Objectifs() {

    const { t } = useTranslation() ;
    const listObjectif = t("about.objectifs.list", {returnObjects: true})
    const [more, setMore] = useState(false);
    const [moreText, setMoreText] = useState("");

    return (
        <div className='flex flex-col md:px-0'>
            <div className='mt-8 sm:mt-14 md:mt-20 lg:mt-28'>
                <div className='flex flex-col items-center'>
                    <Title name={t("about.objectifs.title")}></Title>
                    <p className='mt-16 font-[GothamBook] md:text-[20px] lg:text-[20px] text-center w-[90%] md:w-[70%] lg:w-1/2'>
                        {t("about.objectifs.text")}
                    </p>
                </div>
            </div>


            <div className='objectifs_lists p-10 md:p-11 lg:p-20'>

                <div className='flex flex-wrap justify-between'>
                    {
                        listObjectif.map((objectif, index) => {
                            return (
                                <div key={index} className='flex flex-col w-full md:w-[calc(50%-32px)] lg:w-[calc(33.33%-43px)] mt-10 md:mt-5'>
                                    <h1 className='flex items-center  md:text-[25px] lg:text-[30px] text-[#57b17ed2] font-[GothamBoldItalic] mb-3'>
                                        <span>{objectif.num}</span>
                                        <span className='md:text-[17px] lg:text-[20px] ml-4 text-[#f9be58] font-[GothamBoldItalic]'>{objectif.title}</span>
                                    </h1>
                                    {/* <h1 className='text-[20px] text-[#f9be58] font-[GothamBoldItalic] mb-3'>{objectif.title}</h1> */}
                                    <div className='flex flex-col '>
                                        <div className='flex items-center mb-2'>
                                            <div className='bg-[#57b17ed2] w-3 rounded-md h-[123px] pl-3'></div>
                                            <p className='font-[GothamBook] md:text-[16px] lg:text-[17px] lg:w-[90%] pl-3'>{objectif.goals[1]}</p>
                                        </div>
                                        <button className='self-center bg-[#f9be58] font-[GothamBook] w-fit px-4 py-2' onClick={() => {
                                            setMore(true);
                                            setMoreText(() => {
                                                return (
                                                    <div className='flex flex-col justify-center'>
                                                        <h1 className='flex items-center  text-[#57b17ed2] font-[GothamBoldItalic] mb-3'>
                                                            <span className=''>{objectif.num}</span>
                                                            <span className='md:text-[30px] lg:text-[30px] text-[#f9be58] font-[GothamBoldItalic] ml-3'>{objectif.title}</span>
                                                        </h1>
                                                        <ul className=''>
                                                            {objectif.goals.map((item, i) => {
                                                                return <li className='font-[GothamBook] text-justify mb-5'>
                                                                    <span className='font-[GothamBold]'>{i + 1 + "- "}</span>
                                                                    {item}
                                                                </li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                )
                                            })
                                        }}>{t("about.objectifs.more")}</button>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <Modal autoWidth={true} visible={more} setVisible={setMore}>{moreText}</Modal>
            </div>
        </div>
    )
}

export default Objectifs
