import React, { useState, useEffect } from 'react';
import '../../bootstrap.css';
import './Exposant.css';
import Header from '../../../Components/Header/Header';
import { useTranslation } from 'react-i18next';
function Exposant() {

  const { t, i18n, ready } = useTranslation();
  const isRtl = i18n.language === 'ar';   const exhibitorPage = t("signupPage.exhibitor", { returnObjects: true });

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    window.location.href = '/';
  };

  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: 0,
    birthday: '',
    password: '',
    organisme: '',
    confirmpassword: '',
    ParticipantType: 1,
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = event => {
    setIsLoading(!isLoading);
    event.preventDefault();
    if (formValues.password !== formValues.confirmpassword) {
      console.log('error form');
    } else {
      // TODO: Implement API call to submit form data
      formValues.ParticipantType = 2;
      fetch('https://api.medcop.ma/api/Participants', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          setIsLoading(false);
          handleOpen();

          // TODO: Handle successful response
        })
        .catch(error => {
          setIsLoading(false);

          handleOpen();
        });
    }


  };
  return (
    <div className={isRtl ? 'rtl-container' : ''}> 
      <Header title={t("signupPage.title")} bgImg="/images/participer/exposant-header.jpg"></Header>
      <div className="wrapper">
        <div className="form-left exposantcss">
          <h2 className="text-uppercase">{exhibitorPage.title}</h2>
          <p className='exposant-text'>
            {exhibitorPage.text.p}
          </p>
          <ul className='main-list'>
            <li className='main-li'><strong>{exhibitorPage.text.ul1.li1}</strong></li>
            <li><strong className='main-li' >{exhibitorPage.text.ul1.li2}</strong>
              <ul className='inside-list'>
                <li className='inside-li'>{exhibitorPage.text.ul1.li3}</li>
                <li>{exhibitorPage.text.ul1.li4}</li>
              </ul>
            </li>
            <li><strong className='main-li'>{exhibitorPage.text.ul1.li5}</strong>
              <ul className='inside-list'>
                <li className='inside-li'>{exhibitorPage.text.ul1.li6}</li>
                <li className='inside-li'>{exhibitorPage.text.ul1.li7}</li>
                <li className='inside-li'>{exhibitorPage.text.ul1.li8}</li>
                <li className='inside-li'>{exhibitorPage.text.ul1.li9}</li>
              </ul>
            </li>
          </ul>
          <p className="text">
            <span>{exhibitorPage.text.ul2.span}</span>
            <ul className='tarif-list'>
              <li>{exhibitorPage.text.ul2.li1}</li>
              <li>{exhibitorPage.text.ul2.li2}</li>
              <li>{exhibitorPage.text.ul2.li3}</li>
            </ul>
          </p>
        </div>
        <form className="form-right" onSubmit={handleSubmit}>


          <h2 className="text-uppercase">{exhibitorPage.form.title}</h2>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <label>{exhibitorPage.form.fields.firstName}</label>
              <input type="text" name="firstname" id="firstname" value={formValues.firstname} className="input-field" onChange={handleInputChange} />
            </div>
            <div className="col-sm-6 mb-3">
              <label>{exhibitorPage.form.fields.lastName}</label>
              <input type="text" name="lastname" id="lastname" value={formValues.lastname} className="input-field" onChange={handleInputChange} />
            </div>
          </div>
          <div className="mb-3">
            <label>{exhibitorPage.form.fields.email}</label>
            <input type="email" className="input-field" name="email" value={formValues.email} required onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label>{exhibitorPage.form.fields.phone}</label>
            <input type="tel" className="input-field" name="phone" value={formValues.phone} required onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label>{exhibitorPage.form.fields.dayOfBirth}</label>
            <input type="date" className="input-field" name="birthday" value={formValues.birthday} required onChange={handleInputChange} />
          </div>

          <div className="mb-3">
            <label>{exhibitorPage.form.fields.languages}</label>
            <input type="text" className="input-field" name="langue" value={formValues.langue} required onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label>{exhibitorPage.form.fields.organization}</label>
            <input type="text" className="input-field" name="organisme" value={formValues.organisme} required onChange={handleInputChange} />
          </div>
          {/* <div className="row">
            <div className="col-sm-6 mb-3">
              <label>{exhibitorPage.form.fields.password}</label>
              <input type="password" name="password" id="pwd" className="input-field" onChange={handleInputChange} />
            </div>
            <div className="col-sm-6 mb-3">
              <label>{exhibitorPage.form.fields.confirmPassword}</label>
              <input type="password" name="confirmpassword" id="confirmpassword" value={formValues.confirmpassword} className="input-field" onChange={handleInputChange} />
            </div>
          </div> */}
          <div className="mb-3">
            <label className="option"><a href="#">{exhibitorPage.form.fields.agree}</a>
              <input type="checkbox" defaultChecked />
              <span className="checkmark" />
            </label>
          </div>
          <div className="form-field">
            <input type="submit" defaultValue="Register" className="register" name="register" value={exhibitorPage.form.fields.submit} />
          </div>
        </form>
      </div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>You have been registered successfully</h2>
            <p>We will send you Soon an Email with all Information</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}

      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }

    </div>

  )

}
export default Exposant;