import React from 'react'
import Header from '../../Components/Header/Header'
import Hotels from '../../Components/Hotels/Hotels'
import { useTranslation } from 'react-i18next'

function Hotel() {

  const { t } = useTranslation();
  return (
    <>
      <Header title={t("hotels.title")} bgImg="/images/about-us/cap-spartel.jpg" />
      <Hotels />
      <div>
        <a
          href="https://www.google.com/maps/dir/Tanger+Ville+Railway+Station,+Tangier+90000/Hotel+Andalucia+Golf,+Route+De,+Tangier+90000/Hilton+Tangier+Al+Houara+Resort+%26+Spa%D8%8C+Km+19.8,+National+Route+1+Tangier-Asilah,+Tangier+90100%E2%80%AD%E2%80%AD/Farah+Hotel+Tanger,+El+Ghandouri%D8%8C+Tangier+90000%E2%80%AD%E2%80%AD/@35.7141659,-5.8403106,11.37z/data=!4m26!4m25!1m5!1m1!1s0xd0b809c6e30ee7b:0x76d10e4ea25f6dcc!2m2!1d-5.7858751!2d35.7715827!1m5!1m1!1s0xd0c77d46b673909:0xa4be6d8333df786b!2m2!1d-5.8474179!2d35.7779125!1m5!1m1!1s0xd0b8fc9fe02d907:0x1c98273f308ecd7e!2m2!1d-5.9658372!2d35.6674645!1m5!1m1!1s0xd0c7f93a1bfa4cb:0xbeeec552a5c7968a!2m2!1d-5.7538959!2d35.7929706!3e0?hl=en " target="_blank">
          <img className="" src="images/Gare Tanger Ville.png" alt="" />
        </a>
      </div>
    </>
  )

}

export default Hotel
