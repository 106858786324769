import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';


function Header({ title, bgImg }) {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])
    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <header className="relative flex gap-3 w-full py-10 sm:py-12 md:py-16 lg:py-20 xl:py-24 px-4"
            data-aos="fade-right" data-aos-duration="700"
                style={{
                    backgroundImage: 'url(' + bgImg + ')',
                    backgroundPosition: 'start',
                    backgroundSize: 'cover',
                }}
            >
                <div className="absolute top-0 left-0 z-1 w-full h-full bg-[#0000006c]"></div>
                <div className="relative z-10 w-4 md:w-6 lg:w-7 bg-[#ffffff60]"></div>
                <h1 className="relative z-10 text-[40px] sm:text-[50px] md:text-[65px] lg:text-[75px] font-bold text-white">{title}</h1>
            </header>
        </div>
    )
}

export default Header;