import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
function TalkForm() {
    const { t,i18n } = useTranslation();
    const isRtl = i18n.language === 'ar';    
    

    
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    window.location.href = '/';
  };
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        "firstname": "",
        "lastname": "",
        "email": "",
        "phone": "",
        "message": ""
    });
  
    const handleInputChange = event => {
      const { name, value } = event.target;
      setFormValues({ ...formValues, [name]: value });
    };
  
    const handleSubmit = event => {
      setIsLoading(!isLoading);
      event.preventDefault();
      console.log(JSON.stringify(formValues));
  
    fetch('https://api.medcop.ma/api/Contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formValues)
    })
    .then(response =>{ response.json(); 
        setIsLoading(false);
  
        handleOpen();})
    .then(data => {
      setIsLoading(false);
  
      console.log(data);
      handleOpen();
      // TODO: Handle successful response
    })
    .catch(error => {
      console.error(error);
      // TODO: Handle error
    });
      
     
    
    };
    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <div className=' w-full py-6'>
                <div className='relative flex flex-col justify-center items-center w-[100%]'>
                    <h1 className='font-[GothamBold] text-[25px] sm:text-[38px] md:text-[45px] lg:text-[50px] tracking-wider text-center mb-4'>
                        {t("contact.title")}
                    </h1>
                    {/* <p className='font-[GothamBook] text-[17px] sm:text-[19] md:text-[22px] lg:text-[25px] text-center tracking-normal sm:tracking-normal md:tracking-wide lg:tracking-wide w-2/4 mb-7'>
                    {t("contact.text")}
                    </p> */}
                </div>
                <div className='w-6/12 mx-auto'>
                    <form onSubmit={handleSubmit}>
                        <div className='grid grid-cols-2 '>
                            <div className={!isRtl ? 'rtl-mr-4' : ''}>
                                <input type="text" placeholder={t("contact.form.firstName")} className=' bg-transparent border border-black py-1 px-2 placeholder-black'
                                 value={formValues.firstname}  onChange={handleInputChange} name="firstname"
                                /></div>
                            <input type="text" placeholder={t("contact.form.lastName")} className='bg-transparent border border-black py-1 px-2 placeholder-black'
                            value={formValues.lastname}  onChange={handleInputChange} name="lastname"
                            />
                        </div>
                        <div className='mt-5'>
                            <textarea className='bg-transparent border border-black py-1 px-2 w-full placeholder-black' placeholder={t("contact.form.message")}
                            value={formValues.message}  onChange={handleInputChange}
                            name="message" cols="30" rows="10">
                            </textarea>
                        </div>
                        <div className='mt-5'>
                            <input type="text" placeholder={t("contact.form.email")} className='bg-transparent border border-black py-1 px-2 placeholder-black w-full'
                            value={formValues.email}  onChange={handleInputChange} name="email"
                            />
                        </div>
                        <div className='mt-5'>
                            <input type="text" placeholder={t("contact.form.phone")} className='bg-transparent border border-black py-1 px-2 placeholder-black w-full'
                             value={formValues.phone}  onChange={handleInputChange} name="phone"
                            />
                        </div>
                        <div className='mt-5'>
                            <button className='w-full bg-[#2575BA] py-3 text-center text-white hover:bg-[#2b86d6]'>{t("contact.form.send")}</button>
                        </div>
                    </form>
                </div>
            </div>
            {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Your message has been successfully sent</h2>
            <p>We will contact you soon</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}
      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }
        </div>
    )
}

export default TalkForm;