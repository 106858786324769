import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import MediaComponent from "../../Components/MediaComponent/MediaComponent";
import Title from "../../Components/Title/Title";
import PressArticles from "../../Components/PressArticles/PressArticles";

function MediaPage() {
    const { t } = useTranslation();
    return (
        <div className="pb-20">
            <Header title={t("home.mediaPageTitle", { returnObjects: true })} bgImg={"/images/hotels/tangier-city.jpg"} />
            <PressArticles />
        </div>
    );
}

export default MediaPage;