import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import MediaComponent from "../../Components/MediaComponent/MediaComponent";
import Title from "../../Components/Title/Title";
import PressArticles from "../../Components/PressArticles/PressArticles";

function ThinkTank({ className = "" }) {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';
    const event = t("ThinkTank", { returnObjects: true });
    return (

        <div className="pb-20">
            <Header title={t("nav.links", { returnObjects: true })[6].text} bgImg={"/images/hotels/tangier-city.jpg"} />

            {/* <Title className=" text-[#1B5B92] my-9 " name={t("nav.links", { returnObjects: true })[6].subMenu[5].text} /> */}
            <div className={isRtl ? 'rtl-container' : ''}>
                <div>
                    <div className={'flex flex-col gap-[80px] mt-20 px-[10%] ' + className}>
                        <p className={isRtl ? 'rtl-text' : ''}>
                            <div className={'Hotel_4 flex flex-wrap items-center justify-center '}>
                                <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[50%] object-cover object-center ' src="/images/logo_small.png" alt="" />
                                <div className='aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[50%] py-5 p-5 sm:p-10'>
                                    <h1 className={'text-[30px] text-justify text-[#1b5b92]' + (isRtl ? " font-[arab] " : " font-[GothamBoldItalic] ")}  >
                                        {t("nav.links", { returnObjects: true })[6].subMenu[4].text}
                                    </h1>
                                    <p className={'text-[18px] text-justify' + (isRtl ? " font-[arab] " : "  font-[GothamBook] ")}>
                                        Climate change is an urgent global challenge that requires
                                        concerted efforts from various stakeholders to mitigate its impacts and
                                        promote sustainable development. Think tanks, as policy research and
                                        analysis institutions, play a crucial role in shaping policy decisions by
                                        conducting research, providing evidence-based analysis, and offering
                                        innovative solutions. This concept note proposes a scientific
                                        symposium aimed at enhancing the contributions of think tanks in
                                        addressing climate change, encompassing research, advocacy, and
                                        collaboration across multiple sectors.
                                    </p>
                                    <div className='flex gap-5'>
                                        {/* view site button */}
                                        <a target="_blank" href="/pdf/ThinkTank.pdf" className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4' >
                                            {event.visit}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default ThinkTank;