import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function ParticipateButton({ setMenuOpen }) {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const closeMenu = (e) => {
        console.log(e);
        if (e.relatedTarget !== null && !e.relatedTarget.classList.contains("participate-child")) {
            setOpen(false);
        }
        else if (e.relatedTarget === null) {
            setOpen(false);
        }
    }

    const linkBlur = (e) => {
        if (e.relatedTarget !== null && !e.relatedTarget.classList.contains("participate-child")) {
            setOpen(false);
        }
        else if (e.relatedTarget === null) {
            setOpen(false);
        }
    }

    return (
        <div className="relative flex items-center text-white">
            {/******* The actual button *******/}
            <div className="flex justify-center">
                <NavLink
                    className={"participate flex items-center bg-[#57B17E] px-4 py-1 "
                        + " rounded-bl-[35px]  rounded-r-none "
                        + (open ? " rounded-bl-none rounded-tl-[17.5px] " : " rounded-tl-[35px] ")}
                    to={t("nav.signup.link")}
                    onClick={() => {
                        setOpen(false);
                        setMenuOpen(false);
                    }}
                >
                    <span>{t("nav.signup.text")}</span>

                </NavLink>

                <button className={"px-3 bg-[#53a677] rounded-tr-[17.5px] "
                    + (open ? " rounded-br-[0px] rounded-tr-[17.5px] " : " rounded-br-[17.5px] ")
                }
                    onClick={() => {
                        setOpen(open ? false : true);
                    }}
                    onBlur={(e) => { closeMenu(e) }}
                >
                    <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} ></FontAwesomeIcon>

                </button>
            </div>

            {/******* The List *******/}
            <ul className={"absolute bottom-1 left-0 translate-y-full w-full "
                + " overflow-hidden  text-[#444] rounded-b-[17.5px] "
                + (open ? " transition-[max-height] duration-500 max-h-[150px] " : " max-h-0 ")}
            >
                {t("nav.signup.links", { returnObjects: true }).map((item) => {
                    return <li className="flex w-full">
                        <NavLink
                            className={({ isActive }) => {
                                return ("participate-child w-full py-1 px-4 bg-[#a3e1be] hover:bg-[#53A677] hover:text-[#fff] cursor-pointer  "
                                    + (isActive ? ' bg-[#64c68e] ' : ''))
                            }}
                            to={item.url}
                            onClick={() => {
                                setOpen(false);
                                setMenuOpen(false);
                            }}
                            onBlur={(e) => linkBlur(e)}
                        >{item.text}</NavLink>
                    </li>
                })}
                
            </ul>

        </div>

    );
}

export default ParticipateButton;