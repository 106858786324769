import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faStar } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal/Modal';
import hotelsList from './hotels-list.json';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hotels() {

    const { t } = useTranslation();

    const [more, setMore] = useState(false);
    const [moreText, setMoreText] = useState("");

    return (
        <>
            <div className='flex flex-col mt-20'>
                {t("hotels.list", {returnObjects:true}).map((item, index) => {
                    return (
                        <div className={'Hotel_4 flex flex-wrap items-center'
                            + (index % 2 == 1 ? " flex-row-reverse " : "")}>
                            <div className={'hotelIMG w-full lg:w-1/2 '}>
                                <img className='' src={"/images/hotels/" + item.image} alt="" />
                            </div>
                            <div className='aboutHotel flex flex-col space-y-5 items-start w-full lg:w-[50%] py-5 p-5 sm:p-10'>
                                <h1 className='font-[GothamBoldItalic] text-[25px] xl:text-[33px] text-[#3CAA76]'>
                                    {item.name}
                                    <div className='text-[17px]'>
                                        {[1, 2, 3, 4, 5].map((star, i) => {
                                            return <FontAwesomeIcon color={i >= item.stars ? '#666' : '#F2CE8D'} icon={faStar} />
                                        })}
                                    </div>
                                </h1>
                                <p className='text-[16px] xl:text-[18px] font-[GothamBook] text-justify'>
                                    {item['short-description']}
                                </p>

                                <div className='flex space-x-3'>
                                    {/* view more button */}
                                    <button className='bg-[#F8B133] font-[GothamMedium] text-white text-[16px] xl:text-[18px] rounded-sm px-4 py-4 text-center' onClick={() => {
                                        setMore(true);
                                        setMoreText(() => {
                                            return (
                                                <div className='flex flex-col justify-center '>
                                                    <h1 className='font-[GothamBoldItalic] text-[33px] text-[#3CAA76]'>
                                                        {item.name}
                                                    </h1>
                                                    <p className='font-[GothamBook] mt-4'>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }}>{item.more}</button>

                                    {/* book now button */}
                                    <Link to={"/book-hotel/" + item.url} className='bg-[#F8B133] font-[GothamMedium] text-white text-[16px] xl:text-[18px] rounded-sm px-4 py-4  text-center'>
                                        {item.book}
                                    </Link>

                                    {/* location link */}
                                    <a className='bg-[#F8B133] font-[GothamMedium] text-white text-[16px] xl:text-[18px] rounded-sm px-4 py-4  text-center' href={item.locationURL} target='_blank'>{item.buttonLocation}</a>
                                </div>
                            </div>
                        </div >
                    )
                })}
            </div >
            <Modal autoWidth={true} visible={more} setVisible={setMore}>{moreText}</Modal>
        </>

    )
}

export default Hotels
