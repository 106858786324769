import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";

import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

function OutNow() {

    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <div className='w-full pt-6' >
                <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col justify-center items-start w-full md:w-1/2 py-24 px-16 bg-[#D1EFFA] text-black'
                        data-aos="fade-right" data-aos-offset="200"
                    >
                        <h1 className="flex flex-col items-center text-[35px] font-bold tracking-wider">
                            <span>{t("home.outNow.fromTo.title")}</span>
                            <div className="w-[80%] h-2 bg-[#51bdeb3c]"></div>
                        </h1>
                        <p className='font-[GothamBook] w-2/3 mt-5'>
                            {t("home.outNow.fromTo.text")}
                        </p>
                        {/* <h5 className='font-[GothamBook] w-2/3 mt-5'>CLIMATE ACTION 2016-7 10th
                            ANNEVERSARY EDITION FOR COP22,
                            MARRAKECH, MOROCCO
                        </h5> */}
                    </div>
                    <div className='w-full md:w-1/2 py-24 px-16 bg-[#A2D487]'
                        data-aos="fade-left" data-aos-offset="300"
                    >
                        <h1 className='font-[GothamMedium] text-white text-2xl'>{t("home.outNow.exhibitions.title")}</h1>
                        <p className='font-[GothamBook] text-white mt-5 w-[80%]'>
                        {t("home.outNow.exhibitions.text")}
                            {/* Erik Solheim <br />
                            Hakima El Haité <br />
                            Patricia Espinosa <br />
                            Dr Hoesung lee <br />
                            Janose Pasztor <br />
                            Barbara Hendricks <br />
                            Dr Hoesung lee <br />
                            Janose Pasztor <br />
                            Barbara Hendricks <br />
                            Dr Hoesung lee <br />
                            Janose Pasztor <br /> */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OutNow;