import { useTranslation } from 'react-i18next';
import "./Press.css";
import Header from '../../Components/Header/Header';
import { useState } from 'react';
import Modal from '../../Components/Modal/Modal';
import PressArticles from '../../Components/PressArticles/PressArticles';

function Press() {

  const { t, i18n, ready } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [more, setMore] = useState(false);
  const [moreText, setMoreText] = useState("");



  return (
    <>
      <Header title={t("press.title", { returnObjects: true })} bgImg="/images/about-us/tangier-night.jpg"></Header>
      <div className={isRtl ? 'rtl-container' : ''}>
      </div>

      <PressArticles />
    </>
  )
}

export default Press;
