import './Footer.css';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';


function Footer() {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';  
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
      setIsOpen(true);
    };
  
    const handleClose = () => {
      setIsOpen(false);
    };
      const [isLoading, setIsLoading] = useState(false);
      const [formValues, setFormValues] = useState({
          "firstname": "",
          "lastname": "",
          "email": "",
          "phone": "",
          "message": ""
      });
    
      const handleInputChange = event => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
      };
    
      const handleSubmit = event => {
        setIsLoading(!isLoading);
        event.preventDefault();
        console.log(JSON.stringify(formValues));
    
      fetch('https://api.medcop.ma/api/Contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
      .then(response =>{ response.json(); 
          setIsLoading(false);
    
          handleOpen();})
      .then(data => {
        setIsLoading(false);
    
        console.log(data);
        handleOpen();
        // TODO: Handle successful response
      })
      .catch(error => {
        console.error(error);
        // TODO: Handle error
      });
        
       
      
      };
    
    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <footer className="flex flex-col px-6 pt-10 bg-[#bdbdbd10]"

            >

                {/******** main footer ********/}
                <div className="flex flex-col gap-8 md:flex-row lg:gap-0 w-full">

                    {/******** About ********/}
                    <div className="hidden lg:flex flex-col gap-6 w-full lg:w-1/3 px-10">
                        <h1 className="text-[25px] font-[GothamMedium]">{t("footer.about.title", { returnObjects: true })}</h1>
                        <img className="w-[90%]" src="/images/logo_medcop.png" alt="" />
                        <p className="text-sm text-justify font-[GothamBook] tracking-wider leading-relaxed">
                            {t("footer.about.text", { returnObjects: true })}
                        </p>
                    </div>

                    {/******** contact ********/}
                    <div className="flex flex-col gap-6 w-full md:w-1/2 lg:w-1/3 px-10">
                        <h1 className="text-[25px] font-[GothamMedium]">{t("footer.form.title", { returnObjects: true })}</h1>
                        <form className="flex flex-col space-y-5 font-[GothamBook]" onSubmit={handleSubmit}>
                            <input className="w-full px-4 py-[10px] rounded-[5px] outline-none bg-[#DEE8EB]" value={formValues.firstname}  onChange={handleInputChange} name="firstname" type="text" placeholder={t("footer.form.name", { returnObjects: true }) + " *"} />
                            <input className="w-full px-4 py-[10px] rounded-[5px] outline-none bg-[#DEE8EB]" value={formValues.email}  onChange={handleInputChange} name="email"  type="text" placeholder={t("footer.form.email", { returnObjects: true }) + " *"} />
                            <textarea className="w-full px-4 py-[10px] rounded-[5px] outline-none bg-[#DEE8EB] resize-none"
                            value={formValues.message}  onChange={handleInputChange} name="message"
                                placeholder={t("footer.form.message", { returnObjects: true }) + " *"} rows="5"
                            ></textarea>
                            <div className="flex justify-end">
                                <button className="px-4 py-2 bg-[#F4AE3F] text-white font-[GothamMedium] tracking-wider" type="submit">{t("footer.form.submit", { returnObjects: true })}</button>
                            </div>
                        </form>
                    </div>

                    {/******** follow ********/}
                    <div className="flex flex-col gap-8 w-full md:w-1/2 lg:w-1/3 px-10">
                        <h1 className="text-[25px] font-[GothamMedium]">{t("footer.follow.title", { returnObjects: true })}</h1>
                        <p className="text-lg text-justify font-[GothamBook] tracking-wider leading-loose">
                            {t("footer.follow.text", { returnObjects: true })}
                        </p>
                        <label>contact@medcop.ma</label>
                        <div className="footer-icons flex gap-6 mt-6">

                            {/**** Facebook Icon ****/}
                            <a className="scale-90" href="https://www.facebook.com/profile.php?id=100092592355874&sk=about_details" target='_blank' >
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M44.5161 22.3935C44.5161 10.0229 34.5539 0 22.2581 0C9.96228 0 0 10.0229 0 22.3935C0 33.5704 8.13945 42.8348 18.7802 44.5161V28.8669H13.126V22.3935H18.7802V17.4597C18.7802 11.8478 22.101 8.74792 27.1871 8.74792C29.623 8.74792 32.1701 9.18495 32.1701 9.18495V14.693H29.3627C26.5984 14.693 25.7359 16.4195 25.7359 18.1902V22.3935H31.9089L30.9217 28.8669H25.7359V44.5161C36.3767 42.8348 44.5161 33.5704 44.5161 22.3935Z" fill="#2475BA" />
                                    <path d="M44.5161 22.3935C44.5161 10.0229 34.5539 0 22.2581 0C9.96228 0 0 10.0229 0 22.3935C0 33.5704 8.13945 42.8348 18.7802 44.5161V28.8669H13.126V22.3935H18.7802V17.4597C18.7802 11.8478 22.101 8.74792 27.1871 8.74792C29.623 8.74792 32.1701 9.18495 32.1701 9.18495V14.693H29.3627C26.5984 14.693 25.7359 16.4195 25.7359 18.1902V22.3935H31.9089L30.9217 28.8669H25.7359V44.5161C36.3767 42.8348 44.5161 33.5704 44.5161 22.3935Z" fill="#2475BA" />
                                    <path d="M44.5161 22.3935C44.5161 10.0229 34.5539 0 22.2581 0C9.96228 0 0 10.0229 0 22.3935C0 33.5704 8.13945 42.8348 18.7802 44.5161V28.8669H13.126V22.3935H18.7802V17.4597C18.7802 11.8478 22.101 8.74792 27.1871 8.74792C29.623 8.74792 32.1701 9.18495 32.1701 9.18495V14.693H29.3627C26.5984 14.693 25.7359 16.4195 25.7359 18.1902V22.3935H31.9089L30.9217 28.8669H25.7359V44.5161C36.3767 42.8348 44.5161 33.5704 44.5161 22.3935Z" fill="#2475BA" />
                                </svg>
                            </a>

                            {/**** Twitter Icon ****/}
                            <a className="scale-90" href="https://twitter.com/medcop23" target='_blank' >
                                <svg width="51" height="43" viewBox="0 0 51 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M45.4591 11.3224C45.4911 11.7768 45.4911 12.2313 45.4911 12.6857C45.4911 26.5459 35.0871 42.5162 16.0716 42.5162C10.2133 42.5162 4.77117 40.7957 0.193359 37.8096C1.02572 37.9069 1.82595 37.9393 2.69033 37.9393C7.52415 37.9393 11.974 36.284 15.5274 33.46C10.9816 33.3625 7.17207 30.3438 5.85953 26.1889C6.49984 26.2863 7.14004 26.3512 7.81237 26.3512C8.7407 26.3512 9.66914 26.2213 10.5334 25.9942C5.79558 25.0203 2.24208 20.8006 2.24208 15.7044V15.5747C3.61856 16.3537 5.21933 16.8406 6.91587 16.9054C4.13077 15.0227 2.30613 11.8093 2.30613 8.17375C2.30613 6.2262 2.81823 4.44092 3.71464 2.88285C8.80466 9.24494 16.4557 13.3997 25.035 13.8542C24.8749 13.0752 24.7789 12.2638 24.7789 11.4523C24.7789 5.67437 29.3887 0.967773 35.1189 0.967773C38.0961 0.967773 40.7851 2.2337 42.6739 4.27866C45.0107 3.82425 47.2516 2.94779 49.2365 1.74681C48.4681 4.18134 46.8355 6.2263 44.6907 7.5246C46.7715 7.29749 48.7883 6.71309 50.645 5.90168C49.2367 7.97901 47.4759 9.82914 45.4591 11.3224Z" fill="#51BDEB" />
                                    <path d="M45.4591 11.3224C45.4911 11.7768 45.4911 12.2313 45.4911 12.6857C45.4911 26.5459 35.0871 42.5162 16.0716 42.5162C10.2133 42.5162 4.77117 40.7957 0.193359 37.8096C1.02572 37.9069 1.82595 37.9393 2.69033 37.9393C7.52415 37.9393 11.974 36.284 15.5274 33.46C10.9816 33.3625 7.17207 30.3438 5.85953 26.1889C6.49984 26.2863 7.14004 26.3512 7.81237 26.3512C8.7407 26.3512 9.66914 26.2213 10.5334 25.9942C5.79558 25.0203 2.24208 20.8006 2.24208 15.7044V15.5747C3.61856 16.3537 5.21933 16.8406 6.91587 16.9054C4.13077 15.0227 2.30613 11.8093 2.30613 8.17375C2.30613 6.2262 2.81823 4.44092 3.71464 2.88285C8.80466 9.24494 16.4557 13.3997 25.035 13.8542C24.8749 13.0752 24.7789 12.2638 24.7789 11.4523C24.7789 5.67437 29.3887 0.967773 35.1189 0.967773C38.0961 0.967773 40.7851 2.2337 42.6739 4.27866C45.0107 3.82425 47.2516 2.94779 49.2365 1.74681C48.4681 4.18134 46.8355 6.2263 44.6907 7.5246C46.7715 7.29749 48.7883 6.71309 50.645 5.90168C49.2367 7.97901 47.4759 9.82914 45.4591 11.3224Z" fill="#51BDEB" />
                                    <path d="M45.4591 11.3224C45.4911 11.7768 45.4911 12.2313 45.4911 12.6857C45.4911 26.5459 35.0871 42.5162 16.0716 42.5162C10.2133 42.5162 4.77117 40.7957 0.193359 37.8096C1.02572 37.9069 1.82595 37.9393 2.69033 37.9393C7.52415 37.9393 11.974 36.284 15.5274 33.46C10.9816 33.3625 7.17207 30.3438 5.85953 26.1889C6.49984 26.2863 7.14004 26.3512 7.81237 26.3512C8.7407 26.3512 9.66914 26.2213 10.5334 25.9942C5.79558 25.0203 2.24208 20.8006 2.24208 15.7044V15.5747C3.61856 16.3537 5.21933 16.8406 6.91587 16.9054C4.13077 15.0227 2.30613 11.8093 2.30613 8.17375C2.30613 6.2262 2.81823 4.44092 3.71464 2.88285C8.80466 9.24494 16.4557 13.3997 25.035 13.8542C24.8749 13.0752 24.7789 12.2638 24.7789 11.4523C24.7789 5.67437 29.3887 0.967773 35.1189 0.967773C38.0961 0.967773 40.7851 2.2337 42.6739 4.27866C45.0107 3.82425 47.2516 2.94779 49.2365 1.74681C48.4681 4.18134 46.8355 6.2263 44.6907 7.5246C46.7715 7.29749 48.7883 6.71309 50.645 5.90168C49.2367 7.97901 47.4759 9.82914 45.4591 11.3224Z" fill="#51BDEB" />
                                </svg>
                            </a>

                            {/**** Instagram Icon ****/}
                            <a className="scale-90" href="https://www.linkedin.com/company/medcop-climate/?viewAsMember=true" target='_blank' >
                                <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38.9034 0.967773H3.28122C1.64896 0.967773 0.322754 2.31253 0.322754 3.96334V39.5206C0.322754 41.1714 1.64896 42.5162 3.28122 42.5162H38.9034C40.5357 42.5162 41.8711 41.1714 41.8711 39.5206V3.96334C41.8711 2.31253 40.5357 0.967773 38.9034 0.967773ZM12.88 36.5807H6.72195V16.7524H12.8893V36.5807H12.88ZM9.80098 14.0444C7.82558 14.0444 6.23041 12.44 6.23041 10.4738C6.23041 8.50769 7.82558 6.90326 9.80098 6.90326C11.7671 6.90326 13.3715 8.50769 13.3715 10.4738C13.3715 12.4492 11.7764 14.0444 9.80098 14.0444ZM35.9635 36.5807H29.8054V26.9355C29.8054 24.6355 29.759 21.677 26.6058 21.677C23.3969 21.677 22.9054 24.1811 22.9054 26.7686V36.5807H16.7474V16.7524H22.655V19.4605H22.7385C23.5639 17.9024 25.5764 16.2609 28.5719 16.2609C34.8042 16.2609 35.9635 20.3694 35.9635 25.7113V36.5807Z" fill="#2475BA" />
                                    <path d="M38.9034 0.967773H3.28122C1.64896 0.967773 0.322754 2.31253 0.322754 3.96334V39.5206C0.322754 41.1714 1.64896 42.5162 3.28122 42.5162H38.9034C40.5357 42.5162 41.8711 41.1714 41.8711 39.5206V3.96334C41.8711 2.31253 40.5357 0.967773 38.9034 0.967773ZM12.88 36.5807H6.72195V16.7524H12.8893V36.5807H12.88ZM9.80098 14.0444C7.82558 14.0444 6.23041 12.44 6.23041 10.4738C6.23041 8.50769 7.82558 6.90326 9.80098 6.90326C11.7671 6.90326 13.3715 8.50769 13.3715 10.4738C13.3715 12.4492 11.7764 14.0444 9.80098 14.0444ZM35.9635 36.5807H29.8054V26.9355C29.8054 24.6355 29.759 21.677 26.6058 21.677C23.3969 21.677 22.9054 24.1811 22.9054 26.7686V36.5807H16.7474V16.7524H22.655V19.4605H22.7385C23.5639 17.9024 25.5764 16.2609 28.5719 16.2609C34.8042 16.2609 35.9635 20.3694 35.9635 25.7113V36.5807Z" fill="#2475BA" />
                                    <path d="M38.9034 0.967773H3.28122C1.64896 0.967773 0.322754 2.31253 0.322754 3.96334V39.5206C0.322754 41.1714 1.64896 42.5162 3.28122 42.5162H38.9034C40.5357 42.5162 41.8711 41.1714 41.8711 39.5206V3.96334C41.8711 2.31253 40.5357 0.967773 38.9034 0.967773ZM12.88 36.5807H6.72195V16.7524H12.8893V36.5807H12.88ZM9.80098 14.0444C7.82558 14.0444 6.23041 12.44 6.23041 10.4738C6.23041 8.50769 7.82558 6.90326 9.80098 6.90326C11.7671 6.90326 13.3715 8.50769 13.3715 10.4738C13.3715 12.4492 11.7764 14.0444 9.80098 14.0444ZM35.9635 36.5807H29.8054V26.9355C29.8054 24.6355 29.759 21.677 26.6058 21.677C23.3969 21.677 22.9054 24.1811 22.9054 26.7686V36.5807H16.7474V16.7524H22.655V19.4605H22.7385C23.5639 17.9024 25.5764 16.2609 28.5719 16.2609C34.8042 16.2609 35.9635 20.3694 35.9635 25.7113V36.5807Z" fill="#2475BA" />
                                </svg>
                            </a>

                            {/**** Youtube Icon ****/}
                            <a className="scale-95" href="https://www.youtube.com/@MedcopClimate/about" target='_blank' >
                                <svg width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M54.3043 7.70086C53.6729 5.05057 51.8124 2.96328 49.4503 2.25493C45.1687 0.967773 27.9999 0.967773 27.9999 0.967773C27.9999 0.967773 10.8313 0.967773 6.54962 2.25493C4.18745 2.96339 2.32703 5.05057 1.6956 7.70086C0.54834 12.5047 0.54834 22.5274 0.54834 22.5274C0.54834 22.5274 0.54834 32.5501 1.6956 37.3539C2.32703 40.0042 4.18745 42.0045 6.54962 42.7129C10.8313 44 27.9999 44 27.9999 44C27.9999 44 45.1686 44 49.4503 42.7129C51.8124 42.0045 53.6729 40.0042 54.3043 37.3539C55.4516 32.5501 55.4516 22.5274 55.4516 22.5274C55.4516 22.5274 55.4516 12.5047 54.3043 7.70086ZM22.3848 31.6272V13.4275L36.7345 22.5276L22.3848 31.6272Z" fill="#FE1113" />
                                    <path d="M54.3043 7.70086C53.6729 5.05057 51.8124 2.96328 49.4503 2.25493C45.1687 0.967773 27.9999 0.967773 27.9999 0.967773C27.9999 0.967773 10.8313 0.967773 6.54962 2.25493C4.18745 2.96339 2.32703 5.05057 1.6956 7.70086C0.54834 12.5047 0.54834 22.5274 0.54834 22.5274C0.54834 22.5274 0.54834 32.5501 1.6956 37.3539C2.32703 40.0042 4.18745 42.0045 6.54962 42.7129C10.8313 44 27.9999 44 27.9999 44C27.9999 44 45.1686 44 49.4503 42.7129C51.8124 42.0045 53.6729 40.0042 54.3043 37.3539C55.4516 32.5501 55.4516 22.5274 55.4516 22.5274C55.4516 22.5274 55.4516 12.5047 54.3043 7.70086ZM22.3848 31.6272V13.4275L36.7345 22.5276L22.3848 31.6272Z" fill="#FE1113" />
                                    <path d="M54.3043 7.70086C53.6729 5.05057 51.8124 2.96328 49.4503 2.25493C45.1687 0.967773 27.9999 0.967773 27.9999 0.967773C27.9999 0.967773 10.8313 0.967773 6.54962 2.25493C4.18745 2.96339 2.32703 5.05057 1.6956 7.70086C0.54834 12.5047 0.54834 22.5274 0.54834 22.5274C0.54834 22.5274 0.54834 32.5501 1.6956 37.3539C2.32703 40.0042 4.18745 42.0045 6.54962 42.7129C10.8313 44 27.9999 44 27.9999 44C27.9999 44 45.1686 44 49.4503 42.7129C51.8124 42.0045 53.6729 40.0042 54.3043 37.3539C55.4516 32.5501 55.4516 22.5274 55.4516 22.5274C55.4516 22.5274 55.4516 12.5047 54.3043 7.70086ZM22.3848 31.6272V13.4275L36.7345 22.5276L22.3848 31.6272Z" fill="#FE1113" />
                                </svg>
                            </a>

                        </div>
                    </div>
                </div>

                {/******** bottom footer ********/}
                <div className="flex flex-col items-end gap-4 px-[5%] py-4 mt-4">
                    <div className="w-full h-[1px] bg-[#979797a4]"></div>
                    <span>{t("copyright", { returnObjects: true })}</span>
                </div>
            </footer>
            {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Your message has been successfully sent</h2>
            <p>We will contact you soon</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}
      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }
        </div>
    );
}

export default Footer;