import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import Sponsors from "../../Components/Sponsors/Sponsors";
import TalkForm from "../../Components/TalkForm/TalkForm";

function Contact() {
    const { t } = useTranslation();
    return (
        <>
            <Header title={t("contact.titleHeader")} bgImg="/images/contact-us/bab-bhar.jpg"></Header>
            <TalkForm></TalkForm>
            <Sponsors></Sponsors>
        </>
    )
}

export default Contact;