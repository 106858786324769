import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Modal.css';
import { useTranslation } from "react-i18next";

function Modal({ visible, setVisible, children, autoWidth = false }) {

    const closeModal = () => {
        setVisible(false);
    }
    const outerClick = (e) => {
        if (e.target.classList.contains("my-modal")) {
            setVisible(false);
        }
    }

    const { t, i18n } = useTranslation();

    const text = () => {
        if (i18n.language === "ar") {
            return "أغلق"
        }
        else if (i18n.language === "fr") {
            return "Fermer"
        }
        else {
            return "Close"
        }
    }

    return (
        <>
            <div className={"my-modal fixed top-0 left-0 z-[99999] w-screen h-screen flex justify-center items-center transition-all duration-500 "
                + (visible ? " translate-y-0 " : " translate-y-[-100%] ")}
                onClick={outerClick}
            >
                <div className={"relative z-[99999] flex flex-col justify-center items-center gap-8 "
                    + " max-h-[80%] px-[30px] md:px-[80px] pt-[60px] pb-[30px] bg-white "
                    + (autoWidth ? " w-auto max-w-[80%] " : " w-[80%] ")}>
                    <div className="scroll overflow-auto w-full">
                        {children}
                    </div>
                    <FontAwesomeIcon
                        className={"absolute top-2 right-4 text-[30px] "}
                        onClick={() => closeModal()} icon={faClose}
                    ></FontAwesomeIcon>

                    <button className="bg-[#FFCC7B] px-8 py-3 font-[GothamBook] font-semibold"
                        onClick={() => closeModal()}
                    >{text()}</button>

                </div>
            </div>
            <div className={"fixed top-0 left-0 z-[99998] w-full h-full bg-[#000000] opacity-0 transition-[background-color,opacity] duration-500 "
                + (visible ? " translate-y-0 opacity-40 " : " translate-y-[-100%] opacity-0 ")}
            />
        </>
    );
}

export default Modal;