import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslation from './lang/en/lang.json';
import frTranslation from './lang/fr/lang.json';
import arTranslation from './lang/ar/lang.json';

let currentLang = localStorage.getItem("lang");

if(!currentLang){
  currentLang = "en";
  localStorage.setItem("lang", currentLang);
}

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
    ar: { translation: arTranslation },
  },
  fallbackLng: currentLang, // Default language if translation is not available
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;