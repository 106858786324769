import React, { useState } from 'react';
import "./Mission.css"
import Modal from '../Modal/Modal';
import Title from '../Title/Title';
import { useTranslation } from 'react-i18next';

function Mission() {

    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const about_text = <div className=''>
        <div className='flex justify-center mb-5 text-center'>
            <Title name={t("about.mission.title", { returnObjects: true })}></Title>
        </div>
        <p className='text-justify mx-3'>
            {t("about.mission.longParag.text",  { returnObjects: true })}
            <ul className='flex flex-col gap-3 mt-3'>
                {t("about.mission.longParag.ul", { returnObjects: true }).map((item) => {
                    return <li className='pl-5 border-l-[5px] border-[#51bdec7f] '>
                        <span className='text-[#F4AD3D] font-semibold'>{item.title}</span>
                        {item.text}
                    </li>
                })}
            </ul>


        </p>
    </div>;

    return (
        <>
            <div className='flex flex-col justify-center items-center mt-8'>
                <div className='relative flex flex-col justify-center items-center w-full md:w-[95%]'>

                    <div className='bigfont my-14' >
                        <span className='meeting'>{t("about.meeting.title")}</span>
                        <div className='meeting2'>
                            <div className='line'></div>
                            <span className='date'>{t("about.meeting.date")}</span>
                            <div className='line'></div>
                        </div>
                    </div>

                    <div className='flex flex-col lg:flex-row items-center lg:items-start w-full'>
                        {/* <video className='w-full lg:w-[60%]' controls autoPlay muted loop>
                            <source src="https://www.youtube.com/watch?v=S0vUn-T-HPY" type="video/mp4" />
                            Your browser does not support HTML video.
                        </video> */}
                        <iframe className='w-full h-[500px] lg:w-[60%]'
                         src={"https://www.youtube.com/embed/" + t("about.mission.videoID") + "/?autoplay=1&mute=1"} frameborder="0">
                        </iframe>

                        <div className='relative translate-y-[-20px] tracking-normal sm:tracking-normal md:tracking-wide lg:translate-y-[-0] lg:translate-x-[-20px] w-[90%] lg:w-[40%] text-justify '>
                            <h2 className='font-[GothamMedium] hidden lg:block text-[28px] tracking-wider mb-2 ml-[50px]'>
                                {t("about.mission.title", {returnObjects: true})}
                            </h2>
                            <div className='bg-[#ffcc7B] px-9 py-9'>
                                <h2 className='font-[GothamMedium] inline-block lg:hidden text-[18px] sm:text-[25px] md:text-[28px] lg:text-[28px] tracking-wide mb-2 '>
                                    {t("about.mission.title", {returnObjects: true})}
                                </h2>
                                <p className='font-[GothamLight] text-[14px] md:text-[16px] mb-4'>
                                    {t("about.mission.shortParag")}
                                </p>
                                <button className='font-[GothamBook] bg-black text-white p-2'
                                    onClick={() => setVisible(true)}>{t("about.mission.more")}</button>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Modal visible={visible} setVisible={setVisible}>{about_text}</Modal>
        </>
    )
}

export default Mission;
