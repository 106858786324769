import React from 'react';
import Mission from '../../Components/Mission/Mission';
import Team from '../../Components/Team/Team';
import Header from '../../Components/Header/Header';
import Objectifs from '../../Components/Objectifs/Objectifs';
import Stakes from '../../Components/Stakes/Stakes';
import { useTranslation } from 'react-i18next';

function About() {

    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';    
    return (
        <>
            <div className={isRtl ? 'rtl-container' : ''}>
                <div >
                    <Header title={t("about.title")} bgImg="/images/about-us/markala.jpg"></Header>
                    <Mission />
                    <Stakes />
                    <Objectifs />
                    {/* <Team />  */}
                </div>
            </div>
        </>
    )
}

export default About;
