import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Organizers.css';
import Title from "../Title/Title";
import AOS from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Organizers() {
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';    const settings = {
        centerMode: true,
        infinite: false,
        centerPadding: '60px',
        slidesToShow: 2,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                }
            }
        ]
    };


    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <div className={isRtl ? 'rtl-text' : ''}>
                <div className="organizers flex flex-col items-center justify-center gap-8 mt-10 mb-16"
                    data-aos="fade-up"
                >
                    <Title name={t("home.organaziersTitle")}></Title>
                    <Slider className='flex items-center justify-center w-[95%] gap-20 mt-[15px]' {...settings}>
                        <div className="slide-child w-1/2">
                            <img className="" src={"/images/sponsors/dar-lmonakh.png"} alt="" />
                        </div>
                        <div className="slide-child w-1/2">
                            <img className="" src={"/images/sponsors/tanger-tetouan.png"} alt="" />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Organizers;
