import React from "react";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";


function Stakes() {
    const { t } = useTranslation();
    const stakes = t("about.stakes.list", { returnObjects: true });

    return (
        <>
            <div className="flex flex-col justify-center items-center mt-20 mb-8">
                <Title name={t("about.stakes.title")} />
                <p className="font-[GothamBook] md:text-[20px] lg:text-[20px] text-center w-[90%] md:w-[70%] lg:w-1/2 mt-4">
                {t("about.stakes.text")}
                </p>
            </div>
            <div className="flex flex-wrap  justify-evenly px-[5%]">
                {stakes.map((item) => {
                    return (
                        <div className="w-full md:w-[calc(50%-43px)] lg:w-[calc(33.33%-43px)] px-5 py-8 mt-5 bg-[#88c63c1e] border-[1px] border-[#57b17e91] rounded-[5px] text-[20px] text-center font-medium text-[#2375BB] " >{item}</div>
                    )
                })}
            </div>
        </>
    )
}

export default Stakes;