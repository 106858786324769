import React, { useState, useEffect } from 'react';
import '../../bootstrap.css';
import './Intervenant.css';
import Header from '../../../Components/Header/Header';
import { useTranslation } from 'react-i18next';

function Intervenant() {

  const { t, i18n, ready } = useTranslation();
  const isRtl = i18n.language === 'ar';   const speakersPage = t("signupPage.speaker", { returnObjects: true });


  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    window.location.href = '/';
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: 0,
    birthday: '',
    password: '',
    organisme: '',
    confirmpassword: '',
    ParticipantType: 1,
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = event => {
    setIsLoading(!isLoading);
    event.preventDefault();
    if (formValues.password !== formValues.confirmpassword) {
      console.log('error form');
    } else {
      // TODO: Implement API call to submit form data
      formValues.ParticipantType = 3;
      fetch('https://api.medcop.ma/api/Participants', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
        .then(response => response.json())
        .then(data => {
          setIsLoading(false);
          console.log(data);
          handleOpen();
          // TODO: Handle successful response
        })
        .catch(error => {
          setIsLoading(false);

    handleOpen();
        });
    }


  };
  return (
    <div className={isRtl ? 'rtl-container' : ''}>
      <Header title={t("signupPage.title")} bgImg="/images/participer/intervenant-hearder.jpg"></Header>
      <div className="wrapper">
        <div className="form-left intervenantcss">
          <h2 className="text-uppercase">{speakersPage.title}</h2>
          <p>
            {speakersPage.text}
          </p>
          {/* <p className="text">
              <span>Sub Head:</span>
              Vitae auctor eu augudsf ut. Malesuada nunc vel risus commodo viverra. Praesent elementum facilisis leo vel.
            </p> */}
        </div>
        <form className="form-right" onSubmit={handleSubmit}>
          <h2 className="text-uppercase">{speakersPage.form.title}</h2>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <label>{speakersPage.form.fields.firstName}</label>
              <input type="text" name="firstname" id="firstname" value={formValues.firstname} className="input-field" onChange={handleInputChange} />
            </div>
            <div className="col-sm-6 mb-3">
              <label>{speakersPage.form.fields.lastName}</label>
              <input type="text" name="lastname" id="lastname" value={formValues.lastname} className="input-field" onChange={handleInputChange} />
            </div>
          </div>
          <div className="mb-3">
            <label>{speakersPage.form.fields.email}</label>
            <input type="email" className="input-field" name="email" value={formValues.email} required onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label>{speakersPage.form.fields.phone}</label>
            <input type="tel" className="input-field" name="phone" value={formValues.phone} required onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label>{speakersPage.form.fields.dayOfBirth}</label>
            <input type="date" className="input-field" name="birthday" value={formValues.birthday} required onChange={handleInputChange} />
          </div>

          <div className="mb-3">
            <label>{speakersPage.form.fields.languages}</label>
            <input type="text" className="input-field" name="langue" value={formValues.langue} required onChange={handleInputChange} />
          </div>

          <div className="mb-3">
            <label>{speakersPage.form.fields.expertise}</label>
            <input type="text" className="input-field" name="expertise-field" value={formValues.expertise} required onChange={handleInputChange} />
          </div>
          {/* <div className="row">
            <div className="col-sm-6 mb-3">
              <label>{speakersPage.form.fields.password}</label>
              <input type="password" name="password" id="pwd" className="input-field" onChange={handleInputChange} />
            </div>
            <div className="col-sm-6 mb-3">
              <label>{speakersPage.form.fields.confirmPassword}</label>
              <input type="password" name="confirmpassword" id="confirmpassword" value={formValues.confirmpassword} className="input-field" onChange={handleInputChange} />
            </div>
          </div> */}
          <div className="mb-3">
            <label className="option"><a href="#">{speakersPage.form.fields.agree}</a>
              <input type="checkbox" defaultChecked />
              <span className="checkmark" />
            </label>
          </div>
          <div className="form-field">
            <input type="submit" defaultValue="Register" className="register" name="register" value={speakersPage.form.fields.submit} />
          </div>
        </form>
      </div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>You have been registered successfully</h2>
            <p>We will send you Soon an Email with all Information</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}

      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }
    </div>

  )

}
export default Intervenant;