import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import './MediaComponent.css';
import articles from './articles.json'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MediaComponent({ className = "" }) {

    const [more, setMore] = useState(false);
    const [moreText, setMoreText] = useState("");
    const { t, i18n, ready } = useTranslation();
    const isRtl = i18n.language === 'ar';
    useEffect(() => {
        console.log(t("home.articles", { returnObjects: true }));
    })

    return (
        <div className={isRtl ? 'rtl-container' : ''}>
            <div>
                <div className={'flex flex-col gap-[80px] mt-20 px-[10%] ' + className}>
                    <p className={isRtl ? 'rtl-text' : ''}>
                        {t("home.articles", { returnObjects: true }).map((item, index) => {
                            return (
                                <div className={'Hotel_4 flex flex-wrap items-center justify-center '
                                    + (index % 2 == 1 ? " flex-row-reverse " : "")}>
                                    <img className='hotelIMG w-[80%] lg:w-[50%] xl:w-[40%] object-cover object-center ' src={item.image} alt="" />
                                    <div className='aboutHotel flex flex-col gap-8 items-start w-full lg:w-[50%] xl:w-[60%] py-5 p-5 sm:p-10'>
                                        <h1 className={'text-[30px] text-justify text-[#1b5b92]' + (isRtl ? " font-[arab] " : " font-[GothamBoldItalic] ")}  >
                                            {item.title}
                                        </h1>
                                        <p className={'text-[18px] text-justify' + (isRtl ? " font-[arab] " : "  font-[GothamBook] ")}>
                                            {item['short-description']}
                                        </p>
                                        <div className='flex gap-5'>
                                            {/* view more button */}
                                            <button className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4' onClick={() => {
                                                setMore(true);
                                                setMoreText(() => {
                                                    return (
                                                        <div className='flex flex-col justify-center px-5 '>
                                                            <h1 className={' text-[33px] text-[#3CAA76]' + (isRtl ? " font-[arab] " : "  font-[GothamBoldItalic] ")}>
                                                                {item.title}
                                                            </h1>
                                                            <p className={' mt-4 text-justify' + (isRtl ? " font-[arab] " : "  font-[GothamBook] ")}>
                                                                {item.description}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }}>{item.more}</button>
                                        </div>
                                    </div>
                                </div >
                            )
                        })}
                    </p>
                </div >
                <Modal autoWidth={true} visible={more} setVisible={setMore}>{moreText}</Modal>
            </div>
        </div>

    )
}

export default MediaComponent;