import { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
// import ProgramList from './ProgramList.json';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function Program() {
    const { t, i18n } = useTranslation();
    let ProgramListFile = t("program.table", { returnObjects: true });
    const dayInit = t("program.day1", { returnObjects: true });
    const [targetDay, setTargetDay] = useState(dayInit);
    const [ProgramList, setProgramList] = useState(ProgramListFile);
    const [Calendar, setCalendar] = useState(ProgramList["Day 1"]);
    const isRtl = i18n.language === 'ar';
    useEffect(() => {
        setCalendar(() => t("program.table.Day 1", { returnObjects: true }));
        console.log("tttttttt", ProgramList);
    }, [t, i18n, ProgramList]);

    useEffect(() => {
        console.log("proooo", ProgramList);
    }, [ProgramList]);


    const setDay1 = () => {
        setTargetDay(() => "Day 1");
        setCalendar(() => { return ProgramList["Day 1"] });
    }

    const setDay2 = () => {
        setTargetDay(() => "Day 2");
        setCalendar(() => { return ProgramList["Day 2"] });
    }

    let daysOfWeek = t("program.daysOfWeek", { returnObjects: true });
    let daysOfMonth = [];
    for (let i = 1; i <= 30; i++) {
        daysOfMonth.push(i);
    }

    return (
        <>
            <div className={isRtl ? 'rtl-container' : ''}>
                <Header title={t("program.title")} bgImg="/images/about-us/tangier-night.jpg"></Header>
            </div>

            <div className={isRtl ? 'rtl-container' : ''}>
                <div className="flex flex-col w-full px-[5%] lg:px-[7%] xl:px-[10%] mb-10">
                    {/******* Title ********/}
                    <div className={isRtl ? 'rtl-container' : ''}>
                        <div className="flex gap-10 w-full mt-16 mb-16"
                            data-aos="fade-right"

                        >
                            <div >
                                <h1 className="text-[50px] font-semibold">
                                    <span>{t("program.schedule")} </span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    {/******* Calendar Container ********/}
                    <div className="flex w-full gap-10 lg:gap-20">
                        {/******** Cards *******/}
                        <div className="flex flex-col sm:flex-row md:flex-col gap-6 w-full md:w-[55%]">
                            <a href="#calendar"
                                data-aos="fade-right"
                                className={"flex flex-col gap-2 border-[1px] border-[#7a7a7a] w-full sm:w-[50%] md:w-full h-[150px] bg-[#F7FDFF] "
                                    + " px-4 py-2 shadow-[9px_9px_0px_3px_#F9CF8E] cursor-pointer "
                                    + " hover:bg-[#F9CF8E] hover:shadow-none duration-500 "}
                                onClick={() => setDay1()}
                            >
                                <h3 className="flex justify-between w-full">
                                    <span className="font-semibold text-[22px] ">{t("program.card1.date")}</span>
                                    <span className="text-[#484848]">{t("program.card1.day")} </span>
                                </h3>
                                <span className="text-[14px] text-[#5e5e5e]">{t("program.card1.duration")}</span>
                            </a>
                            <a href="#calendar"
                                data-aos="fade-left"
                                className={"flex flex-col gap-2 border-[1px] border-[#7a7a7a] w-full sm:w-[50%] md:w-full h-[150px] bg-[#F7FDFF] "
                                    + " px-4 py-2 shadow-[9px_9px_0px_3px_#F9CF8E] cursor-pointer "
                                    + " hover:bg-[#F9CF8E] hover:shadow-none hover:text-[#494949] duration-500 "}
                                onClick={() => setDay2()}
                            >
                                <h3 className="flex justify-between w-full">
                                    <span className="font-semibold text-[22px] ">{t("program.card2.date")}</span>
                                    <span className="text-[#484848]">{t("program.card2.day")}</span>
                                </h3>
                                <span className="text-[14px] text-[#5e5e5e]">{t("program.card2.duration")}</span>
                            </a>
                        </div>
                        {/******** Calendar *******/}
                        {i18n.language === "ar" ? <div className="hidden md:flex flex-row-reverse flex-wrap w-[55%] lg:w-[45%] gap-4"

                        >
                            <div className="flex flex-row-reverse gap-4  w-full  ">
                                {daysOfWeek.map((day, i) => {
                                    return <div key={i} className="flex justify-center items-center w-[calc(14.28%-18px)] text-[#5BB382] text-[20px] ">
                                        <span className="">{day}</span>
                                    </div>
                                })}
                            </div>
                            <div className="w-[calc(14.28%-18px)]"></div>
                            <div className="w-[calc(14.28%-18px)]"></div>
                            <div className="w-[calc(14.28%-18px)]"></div>
                            {daysOfMonth.map((day, i) => {
                                return <a key={i}
                                    href={[22, 23].includes(day) ? '#calendar' : ''}
                                    onClick={() => {
                                        if (day == 22) {
                                            setDay1();
                                        }
                                        else if (day == 23) {
                                            setDay2();
                                        }
                                    }}
                                    className={
                                        "flex justify-center items-center w-[calc(14.28%-18px)] "
                                        + " aspect-square font-semibold text-[18px] "
                                        + " lg:text-[25px] xl:text-[30px] "
                                        + ([22, 23].includes(day) ? " bg-[#F9CF8E] text-[#1f1f1f73]" : " bg-[#DADADA] text-white cursor-default pointer-events-none")
                                    }
                                >
                                    <span className="">{day}</span>
                                </a>
                            })}

                        </div> :
                            <div className="hidden md:flex flex-wrap w-[55%] lg:w-[45%] gap-4"

                            >
                                {daysOfWeek.map((day, i) => {
                                    return <div key={i} className="flex justify-center items-center w-[calc(14.28%-18px)] text-[#5BB382] text-[25px] ">
                                        <span className="">{day}</span>
                                    </div>
                                })}
                                <div className="w-[calc(14.28%-18px)]"></div>
                                <div className="w-[calc(14.28%-18px)]"></div>
                                <div className="w-[calc(14.28%-18px)]"></div>
                                {daysOfMonth.map((day, i) => {
                                    return <a key={i}
                                        href={[22, 23].includes(day) ? '#calendar' : ''}
                                        onClick={() => {
                                            if (day == 22) {
                                                setDay1();
                                            }
                                            else if (day == 23) {
                                                setDay2();
                                            }
                                        }}
                                        className={
                                            "flex justify-center items-center w-[calc(14.28%-18px)] "
                                            + " aspect-square font-semibold text-[18px] "
                                            + " lg:text-[25px] xl:text-[30px] "
                                            + ([22, 23].includes(day) ? " bg-[#F9CF8E] text-[#1f1f1f73]" : " bg-[#DADADA] text-white cursor-default pointer-events-none")
                                        }
                                    >
                                        <span className="">{day}</span>
                                    </a>
                                })}

                            </div>
                        }
                    </div>
                    <div className={"flex justify-between w-full mt-10 " + (isRtl ? " text-left " : " text-right ")} >
                        <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                            href={"/pdf/" + t("program.pdf")} target='_blank'
                        >
                            {t("program.pdfBtn", { returnObjects: true })}
                        </a>
                        <a className='bg-[#57B17E] font-[GothamMedium] text-white text-[20px] rounded-sm px-8 py-4'
                            href={"/pdf/" + t("program.pdf2")} target='_blank'
                        >
                            {t("program.pdfBtn2", { returnObjects: true })}
                        </a>
                    </div>

                    {/******** Program ********/}
                    <div className="border-[1px] border-[#5BB382] mt-10 " id="calendar"
                        data-aos="fade-up"
                    >
                        {/******* Program Title *******/}
                        <div className={"flex justify-between w-full px-5 py-4 bg-[#5BB382] "
                            + " text-[22px] text-white font-semibold "}
                        >
                            <h1>
                                <select className="bg-[#5BB382] border-none outline-none "
                                    onChange={(el) => {
                                        if (el.target.value == "1") {
                                            setDay1();
                                        }
                                        else {
                                            setDay2();
                                        }
                                    }}>
                                    {targetDay && targetDay === "Day 1" ?
                                        <>
                                            <option value="1" hidden selected>
                                                {t("program.card1.date")}
                                            </option>
                                            <option value="2" >
                                                {t("program.card2.date")}
                                            </option>
                                        </>
                                        : <>
                                            <option value="1" >
                                                {t("program.card1.date")}
                                            </option>
                                            <option value="2" hidden selected>
                                                {t("program.card2.date")}
                                            </option>
                                        </>
                                    }
                                </select>
                            </h1>
                            <h1 className="uppercase">
                                {targetDay === "Day 1" ? t("program.day1") : t("program.day2")}
                            </h1>
                        </div>
                        {/******* Program Body *******/}
                        <div className="px-[3%]  text-16px md:text-[20px]">
                            <div className="flex items-center gap-10 w-full py-4">
                                <div className="w-[20%] text-[25px] ">
                                    {targetDay === "Day 1" ? t("program.day1") : t("program.day2")}
                                </div>
                                <div className="hidden md:inline-block w-[calc(40%-40px)] ">{Calendar.firstHalf.title1}</div>
                                <div className="hidden md:inline-block w-[calc(40%-40px)] ">{Calendar.firstHalf.title2}</div>
                            </div>
                            <div className="h-[0.5px] w-full bg-black mb-10" />
                            {Calendar.firstHalf.rows.map((row, i) => {
                                return row.pause ? (
                                    <div key={i} className="flex items-stretch gap-10  mb-5 ">
                                        <div className="w-[20%] text-center py-1 bg-[#F2CE8D] text-[#544B3A] font-medium ">{row.time}</div>
                                        <div className="flex justify-center items-center w-[calc(80%-40px)] px-3 py-1 bg-[#B9D9CF] text-black font-medium ">{row.pause}</div>
                                    </div>
                                ) :
                                    <div key={i} className="flex items-stretch gap-10  mb-5 ">
                                        <div className="flex items-center justify-center w-[20%] py-1 bg-[#F2CE8D] text-[#544B3A] font-medium text-center ">{row.time}</div>
                                        <div className="flex flex-col md:flex-row items-stretch gap-5 w-[calc(80%-40px)] text-white font-semibold ">
                                            <div className="flex items-center w-full md:w-[calc(50%)] px-3 py-1 bg-[#80C4A0] text-white font-semibold ">{row.col1}</div>
                                            <div className="flex items-center w-full md:w-[calc(50%)] px-3 py-1 bg-[#80C4A0] text-white font-semibold ">{row.col2}</div>
                                        </div>
                                    </div>
                            })}
                            <div className="flex items-center gap-10 w-full py-4">
                                <div className="flex items-center justify-center w-[20%] text-[25px] "></div>
                                <div className="hidden md:inline-block w-[calc(40%-40px)] ">{Calendar.secondHalf.title1}</div>
                                <div className="hidden md:inline-block w-[calc(40%-40px)] ">{Calendar.secondHalf.title2}</div>
                            </div>
                            {/******* Divider *******/}
                            <div className="flex justify-end">
                                <div className="w-[20%] "></div>
                                <div className="h-[0.5px] w-[calc(80%-40px)] bg-black mb-10" />
                            </div>
                            {Calendar.secondHalf.rows.map((row, i) => {
                                return row.pause ? (
                                    <div key={i} className="flex items-stretch gap-10  mb-5 ">
                                        <div className="w-[20%] text-center py-1 bg-[#F2CE8D] text-[#544B3A] font-medium ">{row.time}</div>
                                        <div className="flex justify-center items-center w-[calc(80%-40px)] px-3 py-1 bg-[#B9D9CF] text-black font-medium ">{row.pause}</div>
                                    </div>
                                ) :
                                    (row.title ?
                                        <div key={i} className="flex items-stretch gap-10  mb-5 ">
                                            <div className="flex items-center justify-center w-[20%] py-1 bg-[#F2CE8D] text-[#544B3A] font-medium ">{row.time}</div>
                                            <div className="flex flex-col items-center gap-3 w-[calc(80%-40px)] py-4">
                                                <div className="w-[calc(80%-40px)] ">{row.title}</div>
                                                <div className="h-[0.5px] w-full bg-black " />
                                            </div>
                                        </div>
                                        :
                                        <div key={i} className="flex items-stretch gap-10  mb-5 ">
                                            <div className="flex items-center justify-center w-[20%] py-1 bg-[#F2CE8D] text-[#544B3A] font-medium text-center">{row.time}</div>
                                            <div className="flex flex-col md:flex-row items-stretch gap-5 w-[calc(80%-40px)] text-white font-semibold ">
                                                <div className="flex items-center w-full md:w-[calc(50%)] px-3 py-1 bg-[#80C4A0] text-white font-semibold ">{row.col1}</div>
                                                <div className="flex items-center w-full md:w-[calc(50%)] px-3 py-1 bg-[#80C4A0] text-white font-semibold ">{row.col2}</div>
                                            </div>
                                        </div>
                                    )
                            })}
                            {targetDay == "Day 1" ?
                                <div className="flex items-stretch gap-10  mb-5 ">
                                    <div className="w-[20%] text-center py-1 bg-[#F2CE8D] text-[#544B3A] font-medium ">
                                        {t('program.table.Day 1.secondHalf.lastRow', { returnObjects: true }).time}
                                    </div>
                                    <div className="flex justify-center items-center w-[calc(80%-40px)] px-3 py-1 bg-[#B9D9CF] text-black font-medium ">
                                        {t('program.table.Day 1.secondHalf.lastRow', { returnObjects: true }).ceremony}
                                    </div>
                                </div>
                                :
                                <div className="flex items-stretch gap-10  mb-5 ">
                                    <div className="flex justify-center items-center w-[20%] py-1 bg-[#F2CE8D] text-[#544B3A] font-medium ">
                                        {t('program.table.Day 2.secondHalf.lastRow', { returnObjects: true }).time}
                                    </div>
                                    <div className="flex flex-col justify-center gap-5 items-center w-[calc(80%-40px)]  text-black font-medium ">
                                        <div className="flex justify-center items-center w-full px-3 py-1 bg-[#B9D9CF] ">
                                            {/* STOCK TAKE PANEL AND WAY FORWARD (RECOMMANDATIONS) */}
                                            {t('program.table.Day 2.secondHalf.lastRow', { returnObjects: true }).pause}
                                        </div>
                                        <div className="flex justify-center items-center w-full px-3 py-1 bg-[#B9D9CF] ">
                                            {t('program.table.Day 2.secondHalf.lastRow', { returnObjects: true }).ceremony}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Program;