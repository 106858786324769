import React, { useState, useEffect } from 'react';
import '../../bootstrap.css';
import './Participant.css';
import Header from '../../../Components/Header/Header';
import { useTranslation } from 'react-i18next';

function Participant() {

  const { t, i18n, ready } = useTranslation();
  const isRtl = i18n.language === 'ar';   const participantPage = t("signupPage.participate", {returnObjects:true})

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    window.location.href = '/';
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: 0,
    birthday: '',
    password: '',
    organisme: '',
    confirmpassword:'',
    ParticipantType: 1,
    Country:'',
    Post:''
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = event => {
    setIsLoading(!isLoading);
    event.preventDefault();
    if (formValues.password !== formValues.confirmpassword){
      console.log('error form');
    }else{
  // TODO: Implement API call to submit form data
  formValues.ParticipantType=1;
  fetch('https://api.medcop.ma/api/Participants', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formValues)
  })
  .then(response => response.json())
  .then(data => {
    setIsLoading(false);

    console.log(data);
    handleOpen();
    // TODO: Handle successful response
  })
  .catch(error => {
    setIsLoading(false);

    handleOpen();
  });
    }
   
  
  };
    return (
        <div className={isRtl ? 'rtl-container' : ''}>
          <Header title={t("signupPage.title", {returnObjects:true})} bgImg="/images/participer/participant-header.jpg"></Header>
          <div className="wrapper">
          <div className="form-left participantcss">
            <h2 className="text-uppercase">{participantPage.title}</h2>
            <p>
              {participantPage.text}
            {/* {t("signupPage.participate.text", {returnObjects:true})} */}
            </p>
            {/* <p className="text">
              <span>Sub Head:</span>
              Vitae auctor eu augudsf ut. Malesuada nunc vel risus commodo viverra. Praesent elementum facilisis leo vel.
            </p> */}
          </div>
          <form className="form-right" onSubmit={handleSubmit}>
            <h2 className="text-uppercase">{participantPage.form.title}</h2>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label>{participantPage.form.fields.firstName}</label>
                <input type="text" name="firstname" id="firstname" value={formValues.firstname} className="input-field" onChange={handleInputChange} />
              </div>
              <div className="col-sm-6 mb-3">
                <label>{participantPage.form.fields.lastName}</label>
                <input type="text" name="lastname" id="lastname" value={formValues.lastname} className="input-field" onChange={handleInputChange}/>
              </div>
            </div>
            <div className="mb-3">
              <label>{participantPage.form.fields.email}</label>
              <input type="email" className="input-field" name="email" value={formValues.email} required onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label>{participantPage.form.fields.phone}</label>
              <input type="tel" className="input-field" name="phone" value={formValues.phone} required onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label>{participantPage.form.fields.dayOfBirth}</label>
              <input type="date" className="input-field" placeholder="dd/mm/yy" name="birthday" value={formValues.birthday} required onChange={handleInputChange} />
            </div>
            
            <div className="mb-3">
              <label>{participantPage.form.fields.languages}</label>
              <input type="text" className="input-field" name="langue"  value={formValues.langue} required onChange={handleInputChange} />
            </div>

            <div className="mb-3">
              <label>{participantPage.form.fields.country}</label>
              <input type="text" className="input-field" name="Country"  value={formValues.Country} required onChange={handleInputChange} />
            </div>

            <div className="mb-3">
              <label>{participantPage.form.fields.organization}</label>
              <input type="text" className="input-field" name="organisme"  value={formValues.organisme} required onChange={handleInputChange} />
            </div>

            <div className="mb-3">
              <label>{participantPage.form.fields.post}</label>
              <input type="text" className="input-field" name="Post"  value={formValues.Post} required onChange={handleInputChange} />
            </div>
            {/* <div className="row">
              <div className="col-sm-6 mb-3">
                <label>{participantPage.form.fields.password}</label>
                <input type="password" name="password" id="pwd"  className="input-field" onChange={handleInputChange} />
              </div>
              <div className="col-sm-6 mb-3">
                <label>{participantPage.form.fields.confirmPassword}</label>
                <input type="password" name="confirmpassword" id="confirmpassword" value={formValues.confirmpassword}  className="input-field" onChange={handleInputChange} />
              </div>
            </div> */}
            <div className="mb-3">
              <label className="option"><a href="#">{participantPage.form.fields.agree}</a>
                <input type="checkbox" defaultChecked />
                <span className="checkmark" />
              </label>
            </div>
            <div className="form-field">
              <input type="submit" defaultValue="Register" className="register" name="register" value={participantPage.form.fields.submit} />
            </div>
          </form>
                </div>
               
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>You have been registered successfully</h2>
            <p>We will send you Soon an Email with all Information</p>
            <button onClick={handleClose}>ِClose</button>
          </div>
        </div>
      )}
      {isLoading && (<div className="overlay">
        <div className="loader" />
      </div>)
      }
        </div>

    )

}
export default Participant;