import '../bootstrap.css';
import './login.css';

function Login(){

    return(
        <div className='login'>
             <div className="wrapperr fadeInDown">
        <div id="formContent">
          {/* Tabs Titles */}
          {/* Icon */}
          {/* Login Form */}
          <form>
            <input type="text" id="login" className="fadeIn second" name="login" placeholder="Email" />
            <input type="text" id="password" className="fadeIn third" name="login" placeholder="password" />
            <input type="submit" className="fadeIn fourth" defaultValue="Log In" />
          </form>
          {/* Remind Passowrd */}
          <div id="formFooter">
            <a className="underlineHover" href="#">Forgot Password?</a>
          </div>
        </div>
      </div>
        </div>
    )
}export default Login;
