import ClaimSeatForm from "../../Components/ClaimSeatForm/ClaimSeatForm";
import Header from "../../Components/Header/Header";
import Sponsors from "../../Components/Sponsors/Sponsors";

function ClaimSeat() {
    return (
        <>
            <Header title="Claim Your Seat" bgImg="/images/about-us/about-bg.png"></Header>
            <ClaimSeatForm></ClaimSeatForm>
            <Sponsors></Sponsors>
        </>
    )
}

export default ClaimSeat;